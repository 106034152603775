import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import LogBookTagInput from '../components/LogBookTagInput'
import LogBookEntryInput from '../components/LogBookEntryInput'
import LogBookTable from '../components/LogBookTable'
import { CurrentUserContext } from '../contexts/CurrentUser';

const LogBook = props => {
    return (
        <>
            <Paper>
                <LogBookEntryInput projectID={props.match.params.projectID} />
            </Paper>
            <Typography gutterBottom>
                &nbsp;
            </Typography>
            <Paper>
                <LogBookTable projectID={props.match.params.projectID} />
            </Paper>
            <Typography gutterBottom>
                &nbsp;
            </Typography>
            <Paper>
                <CurrentUserContext.Consumer>{ me => { return me.role === "READER" ? null :
                    <LogBookTagInput projectID={props.match.params.projectID} />
                }}</CurrentUserContext.Consumer>
            </Paper>
        </>
    )
}

export default LogBook