import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ChipInput from 'material-ui-chip-input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'

const LogBookTagInput = (props) => {
    const [tagNames, setTagNames] = useState([])

    const { data, loading, error } = useQuery(updateLogTagCache, {
        variables: { projectID: props.projectID },
        onCompleted: data => {setTagNames(data.building.logTags.map(item => item.name))}
    })
    const [ createLogTagMutation ] = useMutation(createLogTag)
    const [ deleteLogTagMutation ] = useMutation(deleteLogTag)

    const handleChipAdd = (chip) => {
        setTagNames([...data.building.logTags.map(item => item.name), chip]);
        createLogTagMutation({
            variables: { name: chip, projectID: props.projectID },
            refetchQueries: [{ query: updateLogTagCache, variables: { projectID: props.projectID }}],
        })
    }
    const handleChipDelete = (chip, index) => {
        setTagNames(data.building.logTags.filter(item => item.name!==chip).map(item => item.name));
        const chipId = data.building.logTags.filter(item => item.name===chip).map(item => item.id)[0];
        deleteLogTagMutation({
            variables: { id: chipId },
            refetchQueries: [{ query: updateLogBookCache, variables: { projectID: props.projectID }}],
        })
    }

    if (error) return (<Typography gutterBottom>Error fetching log entries!</Typography>)
    if (loading || !data.building) return (<CircularProgress size={50} color="secondary" />)

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Manage Tags</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                    <ChipInput
                        value={tagNames}
                        onAdd={(chip) => handleChipAdd(chip)}
                        onDelete={(chip, index) => handleChipDelete(chip, index)}
                    />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

const createLogTag = gql`
mutation createLogTagMutation($projectID: String!, $name: String!) {
    createLogTag(projectID: $projectID, name: $name) {
        id name
        building { id }
    }
}`

const deleteLogTag = gql`mutation deleteLogTag($id: ID!) { deleteLogTag(id: $id) { id } }`

const updateLogTagCache = gql`
query updateCache($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id displayName
        logTags { id name }
    }
}`;

const updateLogBookCache = gql`
query updateCache($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id displayName
        logEntries {
            id name description start end
            logTags { id name }
        }
        logTags { id name }
    }
}`;

export default LogBookTagInput;
