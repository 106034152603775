import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { CurrentUserContext } from '../contexts/CurrentUser';

import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalBarSeries,
} from 'react-vis';
import 'react-vis/dist/style.css'

const BuildingComfortQuery = gql`
query Building ($projectID: String) {
  building (where: {projectID: $projectID}) {
    id
    projectID
    buildingFeatures (where: {feature:{name: "thermal-comfort"}}) {
        id
        reporting
    }
  }
}`

const ResultsTabComfort = props => {
    const { loading, error, data } = useQuery(BuildingComfortQuery, {
        variables: { projectID: props.match.params.projectID },
    })

    if (loading) return (<Paper style={{ padding: 25 }}><CircularProgress size={50} color="secondary" /></Paper>)
    if (error) return (<Paper style={{ padding: 25 }}><Typography>An error occurred - results data not available</Typography></Paper>)
    if (!(data.building && data.building.buildingFeatures && data.building.buildingFeatures.length===1 && data.building.buildingFeatures[0].reporting
        && data.building.buildingFeatures[0].reporting.total && data.building.buildingFeatures[0].reporting.day
        && data.building.buildingFeatures[0].reporting.week && data.building.buildingFeatures[0].reporting.month)) 
        return (<Paper style={{ padding: 25 }}><Typography>No reporting data found</Typography></Paper>)

    const reporting_data = data.building.buildingFeatures[0].reporting;
    const comfort_total = reporting_data.total;
    const month_data = Object.keys(reporting_data.month).sort().map(m => ({ x: m, y: reporting_data.month[m] }))
    const week_data = Object.keys(reporting_data.week).sort().map(w => ({ x: w, y: reporting_data.week[w] }))
    const day_data = Object.keys(reporting_data.day).sort().map(d => ({ x: d, y: reporting_data.day[d] }))

    return (
      <div>
        <CurrentUserContext.Consumer>{ me => { return (
        <Paper style={{ padding: 25 }}>
            <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">
                        Overall time in comfort: {comfort_total.toFixed(2)} %
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">
                        &nbsp;
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h5">
                        Monthly data
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot xType="ordinal" width={750} height={200} margin={{top: 10, right: 10, left: 150, bottom: 90}}>
                        <HorizontalGridLines style={{stroke: '#999999'}} />
                        <XAxis tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                        <YAxis width={140} style={{ text: {fontSize: '12px'} }} />
                        <VerticalBarSeries
                            data={month_data.map(d => ({x: d.x, y: d.y, color:me.client.colorElectricityBaseline}))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                    </XYPlot>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h5">
                        Weekly data
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot xType="ordinal" width={750} height={200} margin={{top: 10, right: 10, left: 150, bottom: 90}}>
                        <HorizontalGridLines style={{stroke: '#999999'}} />
                        <XAxis tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                        <YAxis width={140} style={{ text: {fontSize: '12px'} }} />
                        <VerticalBarSeries
                            data={week_data.map(d => ({x: d.x, y: d.y, color:me.client.colorElectricityBaseline}))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                    </XYPlot>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">
                        Daily data
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot xType="ordinal" width={1500} height={200} margin={{top: 10, right: 10, left: 150, bottom: 90}}>
                        <HorizontalGridLines style={{stroke: '#999999'}} />
                        <XAxis tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                        <YAxis width={140} style={{ text: {fontSize: '12px'} }} />
                        <VerticalBarSeries
                            data={day_data.map(d => ({x: d.x, y: d.y, color:me.client.colorElectricityBaseline}))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                    </XYPlot>
                </Grid>
            </Grid>
        </Paper>
        )}}
        </CurrentUserContext.Consumer>
      </div>
    );
}

export default ResultsTabComfort;
