import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { Switch, Route, Redirect } from 'react-router-dom'

import { LinkTab } from '../components/Common'

import Tabs from '@material-ui/core/Tabs';

import ConfigComponentCalendar from '../components/ConfigComponentCalendar'
import ConfigComponentProfiles from '../components/ConfigComponentProfiles'
import ConfigComponentSpace from '../components/ConfigComponentSpace'
import ConfigComponentSystem from '../components/ConfigComponentSystem'
import ConfigComponentCircuit from '../components/ConfigComponentCircuit'
import ConfigComponentZone from '../components/ConfigComponentZone'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const Config = props => {
  const { classes, match, location } = props;

  if (match.url === location.pathname) {
      return <Redirect to={`${match.url}/calendar`} push={false}></Redirect>
  }

  return (
    <div className={classes.root}>
      <Tabs value={location.pathname} onChange={() => null}>
        <LinkTab value={`${match.url}/calendar`} to={`${match.url}/calendar`} label="Calendar" />
        <LinkTab value={`${match.url}/profiles`} to={`${match.url}/profiles`} label="Profiles" />
        <LinkTab value={`${match.url}/spaces`} to={`${match.url}/spaces`} label="Building & Spaces" />
        <LinkTab value={`${match.url}/systems`} to={`${match.url}/systems`} label="Systems" />
        <LinkTab value={`${match.url}/circuits`} to={`${match.url}/circuits`} label="Circuits" />
        <LinkTab value={`${match.url}/zones`} to={`${match.url}/zones`} label="Zones" />
      </Tabs>
      <Switch>
        <Route exact path={`${match.path}/calendar`} component={ConfigComponentCalendar}></Route>
        <Route exact path={`${match.path}/profiles`} component={ConfigComponentProfiles}></Route>
        <Route exact path={`${match.path}/spaces`} component={ConfigComponentSpace}></Route>
        <Route exact path={`${match.path}/systems`} component={ConfigComponentSystem}></Route>
        <Route exact path={`${match.path}/circuits`} component={ConfigComponentCircuit}></Route>
        <Route exact path={`${match.path}/zones`} component={ConfigComponentZone}></Route>
        <Route render={() => {
          return <Redirect to={`${match.url}/calendar`} push={false} />
        }}></Route>
      </Switch>
    </div>
  )
  
}

Config.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Config);
