import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const source_options = [
  {
    value: 'flanders',
    label: 'Flemish Region',
    reference: '',
    value_ele: 0.330,
    value_gas: 0.202,
    value_hea: 0,
    value_coo: 0,
  },
  {
    value: 'brussels',
    label: 'Brussels Region',
    reference: '',
    value_ele: 0.395,
    value_gas: 0.220,
    value_hea: 0,
    value_coo: 0,
  },
  {
    value: 'netherlands',
    label: 'The Netherlands',
    value_ele: 0.475,
    value_gas: 0.193,
    value_hea: 0,
    value_coo: 0,
  },
  {
    value: 'custom',
    label: 'Custom Value',
  },
];

const INPUT_RULES = {
  value_gas: [0, 1],
  value_ele: [0, 1],
  value_hea: [0, 1],
  value_coo: [0, 1],
}

class DataInputCO2 extends React.Component {
  state = {
    id_ele: null,
    id_gas: null,
    id_hea: null,
    id_coo: null,
    source: "custom",
    reference: "",
    value_ele: "",
    value_gas: "",
    value_hea: "",
    value_coo: "",
    form: {
      isValid: true,
      inputs: {
        value_ele: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_gas: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_hea: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_coo: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        }
      }
    }  
  };

  setStateFromUserInput = userInput => {
    this.setState(userInput)
  }

  handleUserInput = props => {
    if (props.data.building && props.data.building.systemMetrics) {
      const sm_gas = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_gas_co2_emission");
      if (sm_gas) {
        this.setStateFromUserInput(sm_gas.userInput);
        this.setState({id_gas: sm_gas.id});
      }
      const sm_ele = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_electricity_co2_emission");
      if (sm_ele) {
        this.setStateFromUserInput(sm_ele.userInput);
        this.setState({id_ele: sm_ele.id});
      }
      const sm_hea = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_heat_co2_emission");
      if (sm_hea) {
        this.setStateFromUserInput(sm_hea.userInput);
        this.setState({id_hea: sm_hea.id});
      }
      const sm_coo = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_cold_co2_emission");
      if (sm_coo) {
        this.setStateFromUserInput(sm_coo.userInput);
        this.setState({id_coo: sm_coo.id});
      }
    }
  }

  componentDidMount() {
    this.handleUserInput(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleUserInput(nextProps)
  }

  handleUpdate = () => {
    if (this.state.id_gas && this.state.id_ele && this.state.id_coo && this.state.id_hea) {
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_ele,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele, value_hea: this.state.value_hea, value_coo: this.state.value_coo},
            payload: {value: this.state.value_ele},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_coo,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele, value_hea: this.state.value_hea, value_coo: this.state.value_coo},
            payload: {value: this.state.value_coo},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_hea,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele, value_hea: this.state.value_hea, value_coo: this.state.value_coo},
            payload: {value: this.state.value_hea},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
          id: this.state.id_gas,
          userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele, value_hea: this.state.value_hea, value_coo: this.state.value_coo},
          payload: {value: this.state.value_gas},
      },
        refetchQueries: [{
            query: updateCache,
            variables: {
              projectID: this.props.match.params.projectID,
            }
        }],
      }).then(response => {
        //after response
      })
    }
  }
  
  handleChangeSource = (event) => {
    this.setState({ source: event.target.value });
    this.setState({ reference: source_options.find(s => s.value===event.target.value).reference });
    this.setState({ value_ele: source_options.find(s => s.value===event.target.value).value_ele });
    this.setState({ value_gas: source_options.find(s => s.value===event.target.value).value_gas });
    this.setState({ value_coo: source_options.find(s => s.value===event.target.value).value_coo });
    this.setState({ value_hea: source_options.find(s => s.value===event.target.value).value_hea });
  }
  handleChange = name => event => {
    let form = this.state.form
    if (name in INPUT_RULES) {
      const [min, max] = INPUT_RULES[name];
      const value = event.target.value;
      form.inputs[name].isValid = (value >= min && value <= max);
    }
    form.isValid = Object.keys(form.inputs).every((inputName, i) => {
      return form.inputs[inputName].isValid;
    })
    this.setState({form})
    this.setState({[name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item lg={12}>
            <Typography variant="subtitle1">
              Please select the reference value to be used (or pick 'Custom value' and fill out building specific fixed values).
            </Typography>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Source
            </Typography>
          </Grid>
          <Grid item lg={10}>
          <form className={classes.container} autoComplete="off">
              <TextField
                id="source"
                select
                className={classes.textField}
                value={this.state.source}
                onChange={this.handleChangeSource}
                margin="normal"
              >
                {source_options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Reference
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="reference"
                className={classes.textField}
                value={this.state.reference}
                onChange={this.handleChange('reference')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Gas
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_gas"
                error={!this.state.form.inputs.value_gas.isValid}
                className={classes.textField}
                helperText={"CO2 emission factor in CO2 kg/kWh " + (this.state.form.inputs.value_gas.isValid ? "" : "(" + this.state.form.inputs.value_gas.hint + ")")}
                value={this.state.value_gas}
                onChange={this.handleChange('value_gas')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
            Electricity
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_ele"
                error={!this.state.form.inputs.value_ele.isValid}
                className={classes.textField}
                helperText={"CO2 emission factor in CO2 kg/kWh " + (this.state.form.inputs.value_ele.isValid ? "" : "(" + this.state.form.inputs.value_ele.hint + ")" )}
                value={this.state.value_ele}
                onChange={this.handleChange('value_ele')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
            Heat
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_hea"
                error={!this.state.form.inputs.value_hea.isValid}
                className={classes.textField}
                helperText={"Heat emission factor in CO2 kg/kWh " + (this.state.form.inputs.value_hea.isValid ? "" : "(" + this.state.form.inputs.value_hea.hint + ")" )}
                value={this.state.value_hea}
                onChange={this.handleChange('value_hea')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
            Cold
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_coo"
                error={!this.state.form.inputs.value_coo.isValid}
                className={classes.textField}
                helperText={"Cold emission factor in CO2 kg/kWh " + (this.state.form.inputs.value_coo.isValid ? "" : "(" + this.state.form.inputs.value_coo.hint + ")" )}
                value={this.state.value_coo}
                onChange={this.handleChange('value_coo')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              &nbsp;
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <Button className={classes.button} variant="contained" onClick={this.handleUpdate} disabled={!this.state.form.isValid}>
              <Save className={classes.leftIcon} />
              Save CO2 emission factor values
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

DataInputCO2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const generalCO2SystemMetrics = gql`
query generalCO2SystemMetrics($projectID: String!) {
  building(where: {projectID: $projectID}) {
      systemMetrics(
        where:{ AND: [
          {
            system:{
              building:{projectID: $projectID},
              systemTemplate:{name: "systemTemplate_general"}
            },
            systemMetricTemplate:{ name_in: ["general_electricity_co2_emission", "general_gas_co2_emission", "general_heat_co2_emission", "general_cold_co2_emission"]}
          },{
            systemMetricTags_none: { key: "hide" value: "true" }
          }
        ]
        }) {
            id
            payload
            userInput
            systemMetricTags {
              id
              key
              value
            }
            systemMetricTemplate {
                id
                name
            }
        }
    }
}`

const updateSystemMetricPayload = gql`
mutation updateSystemMetricPayload($id: ID!, $userInput: Json!, $payload: Json!) {
  updateSystemMetricPayload(
    id: $id
    payload: $payload
    userInput: $userInput
  ) { id }
}`;

const updateCache = gql`
query updateCache($projectID: String!) {
    building(where: {projectID: $projectID}) {
      id
      systemMetrics(
        where:{ AND: [
          {
            system:{
              building:{projectID: $projectID},
              systemTemplate:{name: "systemTemplate_general"}
            },
            systemMetricTemplate:{ name_in: ["general_electricity_co2_emission", "general_gas_co2_emission", "general_heat_co2_emission", "general_cold_co2_emission"]}
          },{
            systemMetricTags_none: { key: "hide" value: "true" }
          }
        ]
        }) {
            id
            payload
            userInput
            systemMetricTags {
              id
              key
              value
            }
            systemMetricTemplate {
                id
                name
            }
        }
    }
}`;

export default compose(
  graphql(generalCO2SystemMetrics, {options: (props) => ( {variables: {projectID: props.match.params.projectID}} )}),
  graphql(updateSystemMetricPayload, {name : 'updateSystemMetricPayloadMutation'}),
  graphql(updateCache, {name : 'updateCache', options: props => {
    return {variables: {projectID: props.match.params.projectID}}
  }}),
)(withStyles(styles)(DataInputCO2));
