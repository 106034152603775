import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { Switch, Route, Redirect, } from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs'

import { LinkTab } from '../components/Common'

import ResultsTabSavings from '../components/ResultsTabSavings'
import ResultsTabComfort from '../components/ResultsTabComfort'
import ResultsTabBaseline from '../components/ResultsTabBaseline'
import ResultsTabMethodology from '../components/ResultsTabMethodology'
import ResultsTabPreviewSavings from '../components/ResultsTabPreviewSavings'
import ResultsTabPreviewBaseline from '../components/ResultsTabPreviewBaseline'

import { CurrentUserContext } from '../contexts/CurrentUser';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const Results = props => {
  const { classes, match, location } = props;
  if (location.pathname === match.url) {
    return <Redirect to={`${match.url}/savings`} />
  }

  return (
    <CurrentUserContext.Consumer>{ me => { return (
      <div className={classes.root}>
        <Tabs value={location.pathname} onChange={() => null}>
          <LinkTab value={`${match.url}/savings`} to={`${match.url}/savings`} label="Savings" />
          <LinkTab value={`${match.url}/baseline`} to={`${match.url}/baseline`} label="Baseline model" />
          <LinkTab value={`${match.url}/methodology`} to={`${match.url}/methodology`} label="Methodology" />
          { me.role === "SUPERADMIN" &&
            <LinkTab value={`${match.url}/savings-preview`} to={`${match.url}/savings-preview`} label="Savings Preview" />
          }
          { me.role === "SUPERADMIN" &&
            <LinkTab value={`${match.url}/baseline-preview`} to={`${match.url}/baseline-preview`} label="Baseline Preview" />
          }
          { me.role === "SUPERADMIN" &&
            <LinkTab value={`${match.url}/comfort`} to={`${match.url}/comfort`} label="Comfort" />
          }
        </Tabs>
        <Switch>
          <Route exact path={`${match.path}/savings`} component={ResultsTabSavings}></Route>
          <Route exact path={`${match.path}/baseline`} component={ResultsTabBaseline}></Route>
          <Route exact path={`${match.path}/methodology`} component={ResultsTabMethodology}></Route>
          { me.role === "SUPERADMIN" &&
            <Route exact path={`${match.path}/savings-preview`} component={ResultsTabPreviewSavings}></Route>
          }
          { me.role === "SUPERADMIN" &&
            <Route exact path={`${match.path}/baseline-preview`} component={ResultsTabPreviewBaseline}></Route>
          }
          { me.role === "SUPERADMIN" &&
            <Route exact path={`${match.path}/comfort`} component={ResultsTabComfort}></Route>
          }
          <Route render={() => {
            return <Redirect to={`${match.url}/savings`} push={false}></Redirect>
          }}>
          </Route>
        </Switch>
      </div>
    )}}</CurrentUserContext.Consumer>
  );
}

export default withStyles(styles)(Results);
