import React from 'react'
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment'
import { CurrentUserContext } from '../contexts/CurrentUser';

const LogBookEntry = props => {
    const [ deleteLogEntryMutation ] = useMutation(deleteLogEntry)

    const _onDelete = () => {
        deleteLogEntryMutation({
            variables: { id: props.entry.id },
            refetchQueries: [{ query: updateLogEntryCache, variables: { projectID: props.projectID }}],
        })
    }

    return (
        <TableRow key={props.entry.id}>
            <TableCell>{props.entry.name}</TableCell>
            <TableCell>{props.entry.description}</TableCell>
            <TableCell>
                {props.entry.logTags.map(tag =>
                    <Chip key={tag.id} label={tag.name} />
                )}
            </TableCell>
            <TableCell>{moment(props.entry.start).local().format("YYYY-MM-DD HH:mm")}</TableCell>
            <TableCell>{moment(props.entry.end).local().format("YYYY-MM-DD HH:mm")}</TableCell>
            <TableCell>
                <CurrentUserContext.Consumer>{ me => { return me.role === "READER" ? null :
                    <IconButton aria-label="Delete" onClick={_onDelete}>
                        <DeleteIcon />
                    </IconButton>
                }}</CurrentUserContext.Consumer>
            </TableCell>
        </TableRow>
    )
}

const deleteLogEntry = gql`mutation deleteLogEntry($id: ID!) { deleteLogEntry(id: $id) { id } }`

const updateLogEntryCache = gql`
query updateCache($projectID:String!) {
    building(where: {projectID: $projectID}) {
        id displayName
        logEntries {
            id name description start end
            logTags { id name }
        }
    }
}`;

export default LogBookEntry;
