import React from 'react';

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProfileDaySchedule from './ProfileDaySchedule'

const sortProfileDaySchedules = profileDaySchedules => (
  Array.from(profileDaySchedules).sort((a, b) => (a.displayName < b.displayName ? -1 : 1))
);

class ProfileDayScheduleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDaySchedules: null,
    };
  }

  componentDidMount() {
    if(!this.props.data.error && !this.props.data.loading && this.props.data.profileDaySchedules) {
      this.setState({ profileDaySchedules: sortProfileDaySchedules(this.props.data.profileDaySchedules) });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!nextProps.data.error && !nextProps.data.loading && nextProps.data.profileDaySchedules) {
      this.setState({ profileDaySchedules: sortProfileDaySchedules(nextProps.data.profileDaySchedules) });
    }
  }

  render() {
    if (this.props.data.error) {
      return (<Typography gutterBottom>Error fetching profile data!</Typography>)
    };
    if (this.props.data.loading) {
      return (<CircularProgress size={50} color="secondary" />)
    };
    if (!this.props.data.profileDaySchedules) {
      return (<Typography gutterBottom>Error rendering profile!</Typography>)
    };
    if (!this.state.profileDaySchedules) {
      return (<Typography gutterBottom>No data available</Typography>)
    } else {
      return (
        <Paper style={{ padding: 25 }}>
          <Grid container spacing={4}>
            {this.state.profileDaySchedules.map(profileDaySchedule => (
              <Grid item lg={12} md={12} sm={12} key={profileDaySchedule.id}>
                <ProfileDaySchedule profileDaySchedule={profileDaySchedule} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      );
    }
  }
}

const ProfileDayScheduleQuery = gql`
query ProfileDayScheduleQuery ($projectID: String) {
  profileDaySchedules (projectID: $projectID) {
    id
    displayName
    payloadType
    payload
  }
}`;

export default graphql(ProfileDayScheduleQuery, {
  options: (props) => {
    return { variables: { projectID: props.projectID } }
  }
})(ProfileDayScheduleList);
