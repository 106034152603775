import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const getBackgroundColor = (level, client) => {
    switch (level) {
        case "ok":
        case "info":
            return client.colorStateOk;
        case "warning":
            return client.colorStateWarning;
        case "error":
            return client.colorStateError;
        default:
            return client.colorStateInactive;
    }
};

const sortBuildingStates = (buildingStates) => {
    return buildingStates.sort((a, b) => {
        if (a.category.localeCompare(b.category) !== 0) {
            return a.category.localeCompare(b.category);
        }
        return a.title.localeCompare(b.title);
    });
};

const State = (props) => {
    const projectID = props.match.params.projectID;
    const { data, loading, error } = useQuery(getCurrentUser, { variables: { projectID } });

    if (error) {
        return (
            <TableContainer component={Paper}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography gutterBottom>Error fetching building state!</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        );
    }

    if (loading) {
        return (
            <TableContainer component={Paper}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <CircularProgress size={50} color="secondary" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        );
    }

    if (!data.me) {
        return (
            <TableContainer component={Paper}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography gutterBottom>Loading building state...</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        );
    }

    const sortedBuildingStates = sortBuildingStates(data.me.client.buildings[0].buildingStates);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '5%' }}></TableCell>
                        <TableCell style={{ width: '15%' }}>Title</TableCell>
                        <TableCell style={{ width: '30%' }}>Updated at</TableCell>
                        <TableCell style={{ width: '50%' }}>Message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedBuildingStates.map((buildingState) => (
                        <TableRow key={buildingState.id + "_row"}>
                            <TableCell>
                                <Avatar
                                    aria-label={buildingState.level}
                                    alt={buildingState.level}
                                    style={{ backgroundColor: getBackgroundColor(buildingState.level, data.me.client) }}
                                >
                                    &nbsp;
                                </Avatar>
                            </TableCell>
                            <TableCell>
                                <Typography>{buildingState.title}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {new Date(buildingState.updatedAt).toLocaleString('default', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                        timeZoneName: 'short'
                                    })}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{ whiteSpace: 'pre-line' }}>
                                    {buildingState.message.replace(/ ; /gi, "\n")}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const getCurrentUser = gql`
  query getCurrentUser($projectID: String!) {
    me {
      id
      externalBuildingRoles(where: { building: { projectID: $projectID } }) {
        id
        role
        building {
          id
          projectID
          buildingStates {
            id
            category
            level
            title
            message
            payload
            alertValidated
            updatedAt
          }
        }
      }
      client {
        id
        colorStateOk
        colorStateWarning
        colorStateError
        colorStateInactive
        buildings(where: { projectID: $projectID }) {
          id
          projectID
          buildingStates {
            id
            category
            level
            title
            message
            payload
            alertValidated
            updatedAt
          }
        }
      }
    }
  }
`;

export default State;
