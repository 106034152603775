import React from 'react';
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const UploadClientLogo = ({ mutate, clientId }) => {
  const handleChange = ({
    target: {
      validity,
      files: [file]
    }
  }) =>
    validity.valid &&
    mutate({
      variables: { clientId:clientId, file:file },
      refetchQueries: [{
          query: updateClientCache,
          variables: {
            clientId: clientId,
          }
      }],
    })

  return <input type="file" required onChange={handleChange} />
}

const updateClientCache = gql`
  query updateCache($clientId:ID!) {
    client(id:$clientId) {
      id
      logo {
        id
        file { id url }
      }
    }
  }
`
export default graphql(gql`
  mutation($clientId: ID!, $file: Upload!) {
    uploadClientLogo(clientId: $clientId, file: $file) {
      id
    }
  }
`)(UploadClientLogo)
