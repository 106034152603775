import { Buffer } from 'buffer';



function deCompressReporting(compressedReporting){

    if (compressedReporting.data){
    const pako = require("pako")
    const compressData = atob(compressedReporting.data).split('').map(function(e) {
        return e.charCodeAt(0);
    })
    const binData     = new Uint8Array(compressData);
    const results = JSON.parse(Buffer.from(pako.inflate(binData)).toString().replace(/\\/g, ""))
    return results}
    else {
        return compressedReporting
    }
}


export default deCompressReporting