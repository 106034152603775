import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import ChartHistorySystems from './ChartHistorySystems';
import {useSelectedBuilding} from "../contexts/SelectedBuilding";

const OperationAHU = props => {
  const { selectedProjectID }  = useSelectedBuilding();
  return (
    <Paper style={{ padding: 25 }}>
      <Typography variant="subtitle1">Air Handling Units</Typography>
      <Typography>&nbsp;</Typography>
      <Grid container>
        <Grid item xs={12}>
          <ChartHistorySystems
            projectID={selectedProjectID}
            systemMetricGroups={[
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_temperature_air_supply",
                "systemMetricTagFunction": "sensor",
                "axis": "air_temperature",
                "color": "red",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_temperature_air_supply",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "air_temperature",
                "color": "red",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_command_fan_supply",
                "systemMetricTagFunction": "sensor",
                "axis": "status",
                "color": "#763F08",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_command_fan_supply",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "status",
                "color": "#763F08",
                "dashed": true,
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_power_electric",
                "systemMetricTagFunction": "sensor",
                "axis": "power_electric",
                "color": "#2E75B6",
                "addToLegend": true,
              },
              {
                "systemTemplateName": "systemTemplate_ahu",
                "systemMetricTemplateName": "ahu_power_electric",
                "systemMetricTagFunction": "setpoint",
                "systemMetricTagRwc": "read",
                "axis": "power_electric",
                "color": "#2E75B6",
                "dashed": true,
                "addToLegend": true,
              },
            ]}
            systemMetricAxes={[
              {
                "name": "air_temperature",
                "label": "Air temperature [°C]",
              },
              {
                "name": "status",
                "label": "Status",
              },
              {
                "name": "power_electric",
                "label": "Power [kW]",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default OperationAHU
