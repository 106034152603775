import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ConfigSystemTemplate from './ConfigSystemTemplate'

const ConfigComponentSystem = (props) => {
  const projectID = props.match.params.projectID
  const { data, loading, error } = useQuery(getSystemTemplatesForSystems, { variables: { projectID }})

  if (loading) return (<Grid><CircularProgress size={50} color="secondary" /></Grid>)
  if (error) return (<Grid><Typography gutterBottom>Error fetching building configuration!</Typography></Grid>)
  if (!data.systemTemplates) return (<Typography gutterBottom>No Data Found!</Typography>)

  return (
    <div>
      <div>&nbsp;</div>
      {data.systemTemplates && data.systemTemplates.map((systemTemplate, index) => {
        return (
          <ConfigSystemTemplate
            key={systemTemplate.id}
            systemTemplateId={systemTemplate.id}
            projectID={data.building.projectID} />
      )
    })}
    </div>
  )
}

const getSystemTemplatesForSystems = gql`
query getSystemTemplatesForSystems ($projectID: String!) {
  building (where: {projectID: $projectID}) {
    id
    projectID
    displayName
  }
  systemTemplates (systemTemplateNames: ["systemTemplate_ahu","systemTemplate_vrv","systemTemplate_cp","systemTemplate_hp","systemTemplate_dhw","systemTemplate_collector","systemTemplate_emihea", "systemTemplate_emicoo", "systemTemplate_endunit", "systemTemplate_endunit2pipes"]) {
    id
    name
    displayName
  }
}`

export default ConfigComponentSystem
