/**
 * Constants representing different environments.
 */
const GRAPHQL_MAPPING = {
    "local": {  // Connect locally to GraphQL server
        "uri": "http://localhost:30444/",
        "clientId": "COd6mXL1k85D9HmUsGJMTd3xC4ccNCmY",
    },
    "development": {    // Connect to development GraphQL server on GCP
        "uri": "http://dev-machine.dev.dnergy.io:30444/",
        "clientId": "COd6mXL1k85D9HmUsGJMTd3xC4ccNCmY",
    },
    "production": {    // Connect to production GraphQL server
        "uri": "https://graphql.deltaq.io/",
        "clientId": "rdN0YHmLGwpSnc8AGfK8QMZsIWveAO0O",
    },
    "fallback": {     // Connect to production GraphQL server, old Auth0 client
        "uri": "https://graphql.deltaq.io/",
        "clientId": "7YkT6xxScrBBZMzmF0DAXx1wcOD4cwbR",
    },
};

function getMapping() {
    // Develop against development GraphQL server
    if (window.location.href.indexOf("localhost:3000") >= 0) {
        return GRAPHQL_MAPPING["production"]
    }
    // Develop against local GraphQL server
    if (window.location.href.indexOf("localhost:30080") >= 0) {
        return GRAPHQL_MAPPING["local"];
    }
    // Connect to dnergy_prod GraphQL server
    if (window.location.href.indexOf(".deltaq.") >= 0 || window.location.href.indexOf(".dnergy.") >= 0) {
        return GRAPHQL_MAPPING["production"];
    }
    // Default fallback (e.g. netlify staging domains)
    // Connect to production server but with an old Auth0 client
    return GRAPHQL_MAPPING["fallback"];
}

const AppSettings = {
    GraphQLUri: getMapping()["uri"],
    GraphQLAuthDomain: "sqb.eu.auth0.com",
    GraphQLAuthClientID: getMapping()["clientId"],
    GraphQLAuthAudience: "https://graphql.synaptiqbuilding.com/",
    YEARS_OF_INVOICES_PRIOR_TO_ACTIVATION: 3,
    BuildingFeatureTriggerUrl: getMapping()["uri"] + "msg/infra:command:function:call",
    API_BASE_URL: "https://api.deltaq.io",
}

export default AppSettings;
