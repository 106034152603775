import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'

const ConfigGeneral = props => {
  const { projectID, spaceId, onUpdate } = props
  const { data, loading, error } = useQuery(getSpaceData, { variables: { spaceId, projectID } })

  if (loading) return (<CircularProgress size={20} color="secondary" />)
  if (error) return (<Typography gutterBottom>Error fetching Space data!</Typography>)
  if (!data.space) return (<Typography gutterBottom>No Space Found!</Typography>)

  const space = data.space

  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" gutterBottom>{space.displayName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <TableBody>
            {Array.from(space.systemMetrics)
              .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
              .map((systemMetric, index) => {
              return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
              ? (
                <ConfigSystemMetricEdit
                  key={systemMetric.id}
                  projectID={projectID}
                  systemMetricId={systemMetric.id}
                  onUpdate={onUpdate} />
              ) : ( null ))

            })}
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const getSpaceData = gql`
query getSpaceData($spaceId: ID!, $projectID: String!) {
  space (id: $spaceId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    parentSpace {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
  }
}`;

export default ConfigGeneral
