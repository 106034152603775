import React, {Component} from 'react'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress';

import ChartHistoryCustom from './ChartHistoryCustom';

class OperationCustom extends Component {
  render() {
    if (this.props.data.error) {
      return (<Typography gutterBottom>Error fetching chart data!</Typography>)
    };
    if (this.props.data.loading) {
      return (<CircularProgress size={50} color="secondary" />)
    };
    if (!this.props.data.building || this.props.data.building.buildingCharts===0) {
      return (<Typography gutterBottom>No data available</Typography>)
    } else {
      return (
        this.props.data.building.buildingCharts.map((chart, index) => (
          <div key={chart.id}>
            <Paper style={{ padding: 25 }}>
              <Typography variant="subtitle1">{chart.displayName}</Typography>
              <Typography>&nbsp;</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <ChartHistoryCustom
                    dashboardDateTime={this.props.data.building.lastDataPushDateTime}
                    config={chart.config}
                    chartMetrics={chart.chartMetrics}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Typography>&nbsp;</Typography>
          </div>
        ))
      )
    }
  }
}

const OperationCustomQuery = gql`
query OperationCustomQuery ($projectID: String!) {
  building(where: {projectID: $projectID}) {
    id
    lastDataPushDateTime
    buildingCharts {
      id
      displayName
      config
      chartMetrics {
        id
        displayName
        payload
        metric {
          id
          name
          displayName
          description
          databaseUnitString
          defaultDisplayUnitString
        }
      }
    }
  }
}`;

export default graphql(OperationCustomQuery, {
  options: (props) => ({ variables: {
      projectID: props.match.params.projectID,
    }
  })
})(OperationCustom);
