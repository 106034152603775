import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { CurrentUserContext } from '../contexts/CurrentUser';

import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalBarSeries,
    LineMarkSeries,
    AreaSeries
} from 'react-vis';
import deCompressReporting from "./ResultsSavingsDecompression";

const colorHeatBaseline = "#a6d1e3"
const colorHeatConsumption = "#007db0"
const colorHeatConfidence = "#EEEEEE"


const colorColdBaseline = "#509680"
const colorColdConsumption = "#02eba2"
const colorColdConfidence = "#EEEEEE"
const colorOutlier = "#6F7373"
const BuildingSavingsQuery = gql`
query Building ($projectID: String) {
  building (where: {projectID: $projectID}) {
    id
    projectID
    buildingFeatures (where: {feature:{name: "evaluation-ipmvp"}}) {
        id
        reporting
    }
  }
}`

const ResultsTabBaseline = props => {
    const { loading, error, data } = useQuery(BuildingSavingsQuery, { variables: { projectID: props.match.params.projectID }})

    const _yTickFormatValue = (unit) => ((v, i, scale, tickTotal) => {
        return `${(scale.tickFormat(tickTotal, 'd')(v)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00a0')} ` + unit;
    });

    if (loading) return (<Paper style={{ padding: 25 }}><CircularProgress size={50} color="secondary" /></Paper>)
    if (error) return (<Paper style={{ padding: 25 }}><Typography>An error occurred - results data not available</Typography></Paper>)
    if (!(data.building && data.building.buildingFeatures && data.building.buildingFeatures.length===1 && deCompressReporting(data.building.buildingFeatures[0].reporting) && deCompressReporting(data.building.buildingFeatures[0].reporting).public))
        return (<Paper style={{ padding: 25 }}><Typography>No reporting data found</Typography></Paper>)

    const reporting_data = deCompressReporting(data.building.buildingFeatures[0].reporting).public

    const gas_vars = reporting_data.gas
    const gas_vars_chart_data = gas_vars ? (gas_vars.baseline_chart_data && gas_vars.neutralise_period_chart_data ? (Object.keys(gas_vars.baseline_chart_data).sort().concat(Object.keys(gas_vars.neutralise_period_chart_data).sort())).sort() : gas_vars.baseline_chart_data ? Object.keys(gas_vars.baseline_chart_data).sort(): []
    ):[]
    const gas_label_indexes = gas_vars && gas_vars.baseline_chart_data
    ? gas_vars_chart_data.map((k, i) => i)
    : []
    const gas_label_strings = gas_vars && gas_vars.baseline_chart_data
    ? gas_vars_chart_data.map((k) => k.replace('|', ' - '))
    : []
    const gas_confidence_data = gas_vars && gas_vars.baseline_chart_data
    ? gas_vars_chart_data.map((k, i) => ({
        x: i,
        y: gas_vars.baseline_chart_data[k] ? gas_vars.baseline_chart_data[k].interval_u_float:gas_vars.neutralise_period_chart_data[k].interval_u_float,
        y0: gas_vars.baseline_chart_data[k] ? gas_vars.baseline_chart_data[k].interval_l_float:gas_vars.neutralise_period_chart_data[k].interval_l_float
    }))
    : []
    const gas_predicted_data = gas_vars && gas_vars.baseline_chart_data
    ? gas_vars_chart_data.map((k, i) => ({
        x: i,
        y: gas_vars.baseline_chart_data[k] ? gas_vars.baseline_chart_data[k].predicted_float:gas_vars.neutralise_period_chart_data[k].predicted_float
    }))
    : []
    const gas_corrected_data = gas_vars && gas_vars.baseline_chart_data
    ? gas_vars_chart_data.map((k, i) => ({
        x: i,
        y: gas_vars.baseline_chart_data[k] ? gas_vars.baseline_chart_data[k].corrected_float : gas_vars.neutralise_period_chart_data[k].corrected_float,
        isOutlier: gas_vars.outliers && (k in gas_vars.outliers || k.split("|")[0] in gas_vars.outliers)
    }))
    : []

    const electricity_vars = reporting_data.electricity
    const electricity_vars_chart_data = electricity_vars ? (electricity_vars.baseline_chart_data && electricity_vars.neutralise_period_chart_data ? (Object.keys(electricity_vars.baseline_chart_data).sort().concat(Object.keys(electricity_vars.neutralise_period_chart_data).sort())).sort() : electricity_vars.baseline_chart_data ? Object.keys(electricity_vars.baseline_chart_data).sort(): []
    ):[]
    const electricity_label_indexes = electricity_vars && electricity_vars.baseline_chart_data
    ? electricity_vars_chart_data.map((k, i) => i)
    : []
    const electricity_label_strings = electricity_vars && electricity_vars.baseline_chart_data
    ? electricity_vars_chart_data.map((k) => k.replace('|', ' - '))
    : []
    const electricity_confidence_data = electricity_vars && electricity_vars.baseline_chart_data
    ? electricity_vars_chart_data.map((k, i) => ({
        x: i,
        y: electricity_vars.baseline_chart_data[k] ? electricity_vars.baseline_chart_data[k].interval_u_float:electricity_vars.neutralise_period_chart_data[k].interval_u_float,
        y0: electricity_vars.baseline_chart_data[k] ? electricity_vars.baseline_chart_data[k].interval_l_float:electricity_vars.neutralise_period_chart_data[k].interval_l_float
    }))
    : []
    const electricity_predicted_data = electricity_vars && electricity_vars.baseline_chart_data
    ? electricity_vars_chart_data.map((k, i) => ({
        x: i,
        y: electricity_vars.baseline_chart_data[k] ? electricity_vars.baseline_chart_data[k].predicted_float:electricity_vars.neutralise_period_chart_data[k].predicted_float
    }))
    : []
    const electricity_corrected_data = electricity_vars && electricity_vars.baseline_chart_data
    ? electricity_vars_chart_data.map((k, i) => ({
        x: i,
        y: electricity_vars.baseline_chart_data[k] ? electricity_vars.baseline_chart_data[k].corrected_float : electricity_vars.neutralise_period_chart_data[k].corrected_float,
        isOutlier: electricity_vars.outliers && (k in electricity_vars.outliers || k.split("|")[0] in electricity_vars.outliers)
    }))
    : []

    const heat_vars = reporting_data.heat
    
    const heat_vars_chart_data = heat_vars ? (heat_vars.baseline_chart_data && heat_vars.neutralise_period_chart_data ? (Object.keys(heat_vars.baseline_chart_data).sort().concat(Object.keys(heat_vars.neutralise_period_chart_data).sort())).sort() : heat_vars.baseline_chart_data ? Object.keys(heat_vars.baseline_chart_data).sort(): []
    ):[]
    const heat_label_indexes = heat_vars && heat_vars.baseline_chart_data
    ? heat_vars_chart_data.map((k, i) => i)
    : []
    const heat_label_strings = heat_vars && heat_vars.baseline_chart_data
    ? heat_vars_chart_data.map((k) => k.replace('|', ' - '))
    : []
    const heat_confidence_data = heat_vars && heat_vars.baseline_chart_data
    ? heat_vars_chart_data.map((k, i) => ({
        x: i,
        y: heat_vars.baseline_chart_data[k] ? heat_vars.baseline_chart_data[k].interval_u_float:heat_vars.neutralise_period_chart_data[k].interval_u_float,
        y0: heat_vars.baseline_chart_data[k] ? heat_vars.baseline_chart_data[k].interval_l_float:heat_vars.neutralise_period_chart_data[k].interval_l_float
    }))
    : []
    const heat_predicted_data = heat_vars && heat_vars.baseline_chart_data
    ? heat_vars_chart_data.map((k, i) => ({
        x: i,
        y: heat_vars.baseline_chart_data[k] ? heat_vars.baseline_chart_data[k].predicted_float:heat_vars.neutralise_period_chart_data[k].predicted_float
    }))
    : []
    const heat_corrected_data = heat_vars && heat_vars.baseline_chart_data
    ? heat_vars_chart_data.map((k, i) => ({
        x: i,
        y: heat_vars.baseline_chart_data[k] ? heat_vars.baseline_chart_data[k].corrected_float : heat_vars.neutralise_period_chart_data[k].corrected_float,
        isOutlier: heat_vars.outliers && (k in heat_vars.outliers || k.split("|")[0] in heat_vars.outliers)
    }))
    : []

    const cold_vars = reporting_data.cold
    const cold_vars_chart_data = cold_vars ? (cold_vars.baseline_chart_data && cold_vars.neutralise_period_chart_data ? (Object.keys(cold_vars.baseline_chart_data).sort().concat(Object.keys(cold_vars.neutralise_period_chart_data).sort())).sort() : cold_vars.baseline_chart_data ? Object.keys(cold_vars.baseline_chart_data).sort(): []
    ):[]
    
    const cold_label_indexes = cold_vars && cold_vars.baseline_chart_data
    ? cold_vars_chart_data.map((k, i) => i)
    : []
    const cold_label_strings = cold_vars && cold_vars.baseline_chart_data
    ? cold_vars_chart_data.map((k) => k.replace('|', ' - '))
    : []
    const cold_confidence_data = cold_vars && cold_vars.baseline_chart_data
    ? cold_vars_chart_data.map((k, i) => ({
        x: i,
        y: cold_vars.baseline_chart_data[k]? cold_vars.baseline_chart_data[k].interval_u_float:cold_vars.neutralise_period_chart_data[k].interval_u_float,
        y0: cold_vars.baseline_chart_data[k]? cold_vars.baseline_chart_data[k].interval_l_float:cold_vars.neutralise_period_chart_data[k].interval_l_float,
    }))
    : []
    const cold_predicted_data = cold_vars && cold_vars.baseline_chart_data
    ? cold_vars_chart_data.map((k, i) => ({
        x: i,
        y: cold_vars.baseline_chart_data[k] ? cold_vars.baseline_chart_data[k].predicted_float:cold_vars.neutralise_period_chart_data[k].predicted_float
    }))
    : []
    const cold_corrected_data = cold_vars && cold_vars.baseline_chart_data
    ? cold_vars_chart_data.map((k, i) => ({
        x: i,
        y: cold_vars.baseline_chart_data[k] ? cold_vars.baseline_chart_data[k].corrected_float : cold_vars.neutralise_period_chart_data[k].corrected_float,
        isOutlier: cold_vars.outliers && (k in cold_vars.outliers || k.split("|")[0] in cold_vars.outliers)
    }))
    : []

    return (
        <CurrentUserContext.Consumer>{ me => { return ([
            (gas_vars && Object.keys(gas_vars).length && gas_vars.baseline_chart_data)
            ? [
                <Paper key="gas" style={{ padding: 25 }}>
                    <Typography variant="h5">
                        Baseline model gas consumption
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        Selected baseline period: {gas_vars.baseline_start} till {gas_vars.baseline_end}.
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        Formula baseline gas consumption:
                    </Typography>
                    <ul><li>{gas_vars.formula}</li></ul>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        The automatically selected independent variables are:
                    </Typography>
                    <ul>
                        {gas_vars.selected_variables && Object.keys(gas_vars.selected_variables).map(k =>(
                            <li key={k}>{k}: {gas_vars.selected_variables[k]}</li>
                        ))}
                    </ul>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    {gas_vars.corrections && Object.keys(gas_vars.corrections) > 0
                        ?[<Typography variant="body1" key="gas_corrections_title">
                            The following corrections have been made to the baseline to improve the accuracy of the baseline model:
                        </Typography>,
                        <ul key="gas_corrections_list">
                            {Object.keys(gas_vars.corrections).map(c =>(
                                <li key={c}>{c}</li>
                            ))}
                        </ul>]
                        :(<Typography variant="body1">
                            No corrections have been applied to the baseline gas consumption.
                        </Typography>)
                    }
                    {
                        gas_vars.outliers && Object.keys(gas_vars.outliers).length > 0
                        ? (
                            <Typography>
                                Some periods were statistical outliers and thus excluded from the evaluation ({ Object.keys(gas_vars.outliers).join(", ") })
                            </Typography>
                        ) : null
                    }
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        R<sup>2</sup> value baseline model: {gas_vars.rsquared}
                    </Typography>
                    <Typography variant="body1">
                    CVRMSE value baseline model: {gas_vars.cvrmse}
                    </Typography>
                    <Typography variant="body1">
                        NYSU value baseline model: {gas_vars.nysu}
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <XYPlot width={900} height={350} margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}} />
                        <XAxis tickValues={gas_label_indexes} tickFormat={(v, i, scale, tickTotal) => gas_label_strings[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                        <YAxis tickFormat={_yTickFormatValue(gas_vars.unit)} width={140} style={{ text: {fontSize: '12px'} }} />
                        <AreaSeries
                            data={gas_confidence_data}
                            curve={'curveMonotoneX'}
                            style={{ stroke: me.client.colorGasConfidence, fill: me.client.colorGasConfidence }}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={gas_corrected_data.map(d => ({x: d.x, y: d.y, color:d.isOutlier ? colorOutlier : me.client.colorGasConsumption, opacity:d.isOutlier ? 0.4 : 0.7}))}
                            colorType={"literal"}
                            color={me.client.colorGasConsumption}
                            barWidth={0.5}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <LineMarkSeries
                            data={gas_predicted_data}
                            size={2}
                            curve={'curveLinear'}
                            lineStyle={{stroke: me.client.colorGasBaseline}}
                            markStyle={{stroke: me.client.colorGasBaseline, fill: me.client.colorGasBaseline}}
                            style={{ fill: 'none' }}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />

                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:me.client.colorGasConsumption, strokeWidth:1, stroke:me.client.colorGasConsumption}} />
                        </svg>
                        &nbsp;Actual Consumption
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={7}>
                            <rect x={0} y={0} width={14} height={2} style={{fill:me.client.colorGasBaseline, strokeWidth:1, stroke:me.client.colorGasBaseline}} />
                        </svg>
                        &nbsp;Model Consumption
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:me.client.colorGasConfidence, strokeWidth:1, stroke:me.client.colorGasConfidence}} />
                        </svg>
                        &nbsp;Model Confidence Interval
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:colorOutlier, strokeWidth:1, stroke:colorOutlier}} />
                        </svg>
                        &nbsp;Outlier(s)
                    </Typography>
                </Paper>,
                <Typography key="space_gas" variant="body1">
                    &nbsp;
                </Typography>
            ] : <div key="gas"></div>,
            (electricity_vars && Object.keys(electricity_vars).length && electricity_vars.baseline_chart_data)
            ? [
                <Paper key="electricity" style={{ padding: 25 }}>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="h5">
                        Baseline model electricity consumption
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        Selected baseline period: {electricity_vars.baseline_start} till {electricity_vars.baseline_end}.
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        Formula baseline electricity consumption:
                    </Typography>
                    <ul><li>{electricity_vars.formula}</li></ul>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        The automatically selected independent variables are:
                    </Typography>
                    <ul>
                        {Object.keys(electricity_vars.selected_variables).map(k =>(
                            <li key={k}>{k}: {electricity_vars.selected_variables[k]}</li>
                        ))}
                    </ul>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    {electricity_vars.corrections && Object.keys(electricity_vars.corrections).length > 0
                        ?[<Typography variant="body1" key="electricity_corrections_title">
                            The following corrections have been made to the baseline to improve the accuracy of the baseline model:
                        </Typography>,
                        <ul key="electricity_corrections_list">
                            {Object.keys(electricity_vars.corrections).map(c =>(
                                <li key={c}>{c}</li>
                            ))}
                        </ul>]
                        :(<Typography variant="body1">
                            No corrections have been applied to the baseline electricity consumption.
                        </Typography>)
                    }
                    {
                        electricity_vars.outliers && Object.keys(electricity_vars.outliers).length > 0
                        ? (
                            <Typography>
                                Some periods were statistical outliers and thus excluded from the evaluation ({ Object.keys(electricity_vars.outliers).join(", ") })
                            </Typography>
                        ) : null
                    }
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        R<sup>2</sup> value baseline model: {electricity_vars.rsquared}
                    </Typography>
                    <Typography variant="body1">
                    CVRMSE value baseline model: {electricity_vars.cvrmse}
                    </Typography>
                    <Typography variant="body1">
                        NYSU value baseline model: {electricity_vars.nysu}
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <XYPlot width={900} height={350} margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}} />
                        <XAxis tickValues={electricity_label_indexes} tickFormat={(v, i, scale, tickTotal) => electricity_label_strings[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                        <YAxis tickFormat={_yTickFormatValue(electricity_vars.unit)} width={140} style={{ text: {fontSize: '12px'} }} />
                        <AreaSeries
                            data={electricity_confidence_data}
                            curve={'curveMonotoneX'}
                            style={{ stroke: me.client.colorElectricityConfidence, fill: me.client.colorElectricityConfidence }}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                                                <VerticalBarSeries
                            data={electricity_corrected_data.map(d => ({x: d.x, y: d.y, color:d.isOutlier ? colorOutlier : me.client.colorElectricityConsumption, opacity:d.isOutlier ? 0.4 : 0.7}))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.5}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <LineMarkSeries
                            data={electricity_predicted_data}
                            size={2}
                            curve={'curveLinear'}
                            lineStyle={{stroke: me.client.colorElectricityBaseline}}
                            markStyle={{stroke: me.client.colorElectricityBaseline, fill: me.client.colorElectricityBaseline}}
                            style={{ fill: 'none' }}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />

                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:me.client.colorElectricityConsumption, strokeWidth:1, stroke:me.client.colorElectricityConsumption}} />
                        </svg>
                        &nbsp;Actual Consumption
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={7}>
                            <rect x={0} y={0} width={14} height={2} style={{fill:me.client.colorElectricityBaseline, strokeWidth:1, stroke:me.client.colorElectricityBaseline}} />
                        </svg>
                        &nbsp;Model Consumption
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:me.client.colorElectricityConfidence, strokeWidth:1, stroke:me.client.colorElectricityConfidence}} />
                        </svg>
                        &nbsp;Model Confidence Interval
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:colorOutlier, strokeWidth:1, stroke:colorOutlier}} />
                        </svg>
                        &nbsp;Outlier(s)
                    </Typography>
                </Paper>
             ] : <div key="electricity"></div>,
             (heat_vars && Object.keys(heat_vars).length && heat_vars.baseline_chart_data)
             ? [
                 <Paper key="heat" style={{ padding: 25 }}>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="h5">
                         Baseline model heat consumption
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         Selected baseline period: {heat_vars.baseline_start} till {heat_vars.baseline_end}.
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         Formula baseline heat consumption:
                     </Typography>
                     <ul><li>{heat_vars.formula}</li></ul>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         The automatically selected independent variables are:
                     </Typography>
                     <ul>
                         {Object.keys(heat_vars.selected_variables).map(k =>(
                             <li key={k}>{k}: {heat_vars.selected_variables[k]}</li>
                         ))}
                     </ul>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     {heat_vars.corrections && Object.keys(heat_vars.corrections).length > 0
                         ?[<Typography variant="body1" key="heat_corrections_title">
                             The following corrections have been made to the baseline to improve the accuracy of the baseline model:
                         </Typography>,
                         <ul key="heat_corrections_list">
                             {Object.keys(heat_vars.corrections).map(c =>(
                                 <li key={c}>{c}</li>
                             ))}
                         </ul>]
                         :(<Typography variant="body1">
                             No corrections have been applied to the baseline heat consumption.
                         </Typography>)
                     }
                     {
                         heat_vars.outliers && Object.keys(heat_vars.outliers).length > 0
                         ? (
                             <Typography>
                                 Some periods were statistical outliers and thus excluded from the evaluation ({ Object.keys(heat_vars.outliers).join(", ") })
                             </Typography>
                         ) : null
                     }
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         R<sup>2</sup> value baseline model: {heat_vars.rsquared}
                     </Typography>
                     <Typography variant="body1">
                         CVRMSE value baseline model: {heat_vars.cvrmse}
                     </Typography>
                     <Typography variant="body1">
                         NYSU value baseline model: {heat_vars.nysu}
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <XYPlot width={900} height={350} margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                         <HorizontalGridLines style={{stroke: '#999999'}} />
                         <XAxis tickValues={heat_label_indexes} tickFormat={(v, i, scale, tickTotal) => heat_label_strings[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                         <YAxis tickFormat={_yTickFormatValue(heat_vars.unit)} width={140} style={{ text: {fontSize: '12px'} }} />
                         <AreaSeries
                             data={heat_confidence_data}
                             curve={'curveMonotoneX'}
                             style={{ stroke: colorHeatConfidence, fill: colorHeatConfidence }}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                         <LineMarkSeries
                             data={heat_predicted_data}
                             size={2}
                             curve={'curveLinear'}
                             lineStyle={{stroke: colorHeatBaseline}}
                             markStyle={{stroke: colorHeatBaseline, fill: colorHeatBaseline}}
                             style={{ fill: 'none' }}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                         <VerticalBarSeries
                             data={heat_corrected_data.map(d => ({x: d.x, y: d.y, color:d.isOutlier ? colorOutlier : colorHeatConsumption, opacity:d.isOutlier ? 0.4 : 0.7}))}
                             colorType={"literal"}
                             color={colorHeatConsumption}
                             barWidth={0.5}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                     </XYPlot>
                     <Typography variant="body1">
                         <svg width={200} height={14}>
                         </svg>
                         <svg width={14} height={14}>
                             <rect x={0} y={0} width={14} height={14} style={{fill:colorHeatConsumption, strokeWidth:1, stroke:colorHeatConsumption}} />
                         </svg>
                         &nbsp;Actual Consumption
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <svg width={14} height={7}>
                             <rect x={0} y={0} width={14} height={2} style={{fill:colorHeatBaseline, strokeWidth:1, stroke:colorHeatBaseline}} />
                         </svg>
                         &nbsp;Model Consumption
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <svg width={14} height={14}>
                             <rect x={0} y={0} width={14} height={14} style={{fill:colorHeatConfidence, strokeWidth:1, stroke:colorHeatConfidence}} />
                         </svg>
                         &nbsp;Model Confidence Interval
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:colorOutlier, strokeWidth:1, stroke:colorOutlier}} />
                        </svg>
                        &nbsp;Outlier(s)
                     </Typography>
                 </Paper>
              ] : <div key="heat"></div>,
              (cold_vars && Object.keys(cold_vars).length && cold_vars.baseline_chart_data)
             ? [
                 <Paper key="cold" style={{ padding: 25 }}>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="h5">
                         Baseline model cold consumption
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         Selected baseline period: {cold_vars.baseline_start} till {cold_vars.baseline_end}.
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         Formula baseline cold consumption:
                     </Typography>
                     <ul><li>{cold_vars.formula}</li></ul>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         The automatically selected independent variables are:
                     </Typography>
                     <ul>
                         {Object.keys(cold_vars.selected_variables).map(k =>(
                             <li key={k}>{k}: {cold_vars.selected_variables[k]}</li>
                         ))}
                     </ul>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     {cold_vars.corrections && Object.keys(cold_vars.corrections).length > 0
                         ?[<Typography variant="body1" key="cold_corrections_title">
                             The following corrections have been made to the baseline to improve the accuracy of the baseline model:
                         </Typography>,
                         <ul key="cold_corrections_list">
                             {Object.keys(cold_vars.corrections).map(c =>(
                                 <li key={c}>{c}</li>
                             ))}
                         </ul>]
                         :(<Typography variant="body1">
                             No corrections have been applied to the baseline cold consumption.
                         </Typography>)
                     }
                     {
                         cold_vars.outliers && Object.keys(cold_vars.outliers).length > 0
                         ? (
                             <Typography>
                                 Some periods were statistical outliers and thus excluded from the evaluation ({ Object.keys(cold_vars.outliers).join(", ") })
                             </Typography>
                         ) : null
                     }
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <Typography variant="body1">
                         R<sup>2</sup> value baseline model: {cold_vars.rsquared}
                     </Typography>
                     <Typography variant="body1">
                         CVRMSE value baseline model: {cold_vars.cvrmse}
                     </Typography>
                     <Typography variant="body1">
                         NYSU value baseline model: {cold_vars.nysu}
                     </Typography>
                     <Typography variant="body1">
                         &nbsp;
                     </Typography>
                     <XYPlot width={900} height={350} margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                         <HorizontalGridLines style={{stroke: '#999999'}} />
                         <XAxis tickValues={cold_label_indexes} tickFormat={(v, i, scale, tickTotal) => cold_label_strings[i]} tickLabelAngle={-60} height={80} style={{ text: {fontSize: '12px'} }} />
                         <YAxis tickFormat={_yTickFormatValue(cold_vars.unit)} width={140} style={{ text: {fontSize: '12px'} }} />
                         <AreaSeries
                             data={cold_confidence_data}
                             curve={'curveMonotoneX'}
                             style={{ stroke: colorColdConfidence, fill: colorColdConfidence }}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                         <LineMarkSeries
                             data={cold_predicted_data}
                             size={2}
                             curve={'curveLinear'}
                             lineStyle={{stroke: colorColdBaseline}}
                             markStyle={{stroke: colorColdBaseline, fill: colorColdBaseline}}
                             style={{ fill: 'none' }}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                         <VerticalBarSeries
                             data={cold_corrected_data.map(d => ({x: d.x, y: d.y, color:d.isOutlier ? colorOutlier : colorColdConsumption, opacity:d.isOutlier ? 0.4 : 0.7}))}
                             colorType={"literal"}
                             color={colorColdConsumption}
                             barWidth={0.5}
                             getNull={(d) => d.y !== null && d.y !== undefined}
                         />
                     </XYPlot>
                     <Typography variant="body1">
                         <svg width={200} height={14}>
                         </svg>
                         <svg width={14} height={14}>
                             <rect x={0} y={0} width={14} height={14} style={{fill:colorColdConsumption, strokeWidth:1, stroke:colorColdConsumption}} />
                         </svg>
                         &nbsp;Actual Consumption
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <svg width={14} height={7}>
                             <rect x={0} y={0} width={14} height={2} style={{fill:colorColdBaseline, strokeWidth:1, stroke:colorColdBaseline}} />
                         </svg>
                         &nbsp;Model Consumption
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                         <svg width={14} height={14}>
                             <rect x={0} y={0} width={14} height={14} style={{fill:colorColdConfidence, strokeWidth:1, stroke:colorColdConfidence}} />
                         </svg>
                         &nbsp;Model Confidence Interval
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{fill:colorOutlier, strokeWidth:1, stroke:colorOutlier}} />
                        </svg>
                        &nbsp;Outlier(s)
                     </Typography>
                 </Paper>
              ] : <div key="cold"></div>
            ])}}
        </CurrentUserContext.Consumer>
    )
}

export default ResultsTabBaseline;
