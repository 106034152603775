import React from 'react';
import BrowserDetection from 'react-browser-detection';

import { WebAuth }  from 'auth0-js';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AppSettings from './AppSettings';

const handleAuth = (event, prompt_for_login) => {
  event.preventDefault();
  var webAuth = new WebAuth({
    domain: AppSettings.GraphQLAuthDomain,
    clientID: AppSettings.GraphQLAuthClientID,
    redirectUri: window.location.protocol + '//' + window.location.host + '/auth_callback',
    audience: AppSettings.GraphQLAuthAudience,
    responseType: 'token id_token'
  });
  webAuth.authorize({prompt: (prompt_for_login ? "login" : "none")});
}

const browserHandler = {
  chrome: () =>
    <div>
      <Button variant="contained" onClick={(event) => handleAuth(event, false)}>
        Login using Auth0
      </Button>
      <br/><br/>
      <a href="#no-op" style={{color: '#FFFFFF', fontSize:'small'}} onClick={(event) => handleAuth(event, true)}>Login as ...</a>
    </div>,
  firefox: (browser) =>
  <div> 
    <Button variant="contained" onClick={handleAuth}>
      Login using Auth0
    </Button>
    <br/><br/>
    <a href="#no-op" style={{color: '#FFFFFF', fontSize:'small'}} onClick={(event) => handleAuth(event, true)}>Login as ...</a>
    <br/><br/>
    <Typography variant="body1" style={{color: '#FFFFFF'}} noWrap>
      Note: most of the functionality is likely to work with your current browser [detected as "{browser}"],<br/>
      but for an optimal experience, we recommend using a recent version of<br/>
      <a href="https://chrome.google.com" target="_blank" rel="noopener noreferrer" style={{color: '#FFFFFF'}}>the Chrome browser</a><br/>
    </Typography>
  </div>,
  edge: (browser) =>
  <div> 
    <Button variant="contained" onClick={handleAuth}>
      Login using Auth0
    </Button>
    <br/><br/>
    <a href="#no-op" style={{color: '#FFFFFF', fontSize:'small'}} onClick={(event) => handleAuth(event, true)}>Login as ...</a>
    <br/><br/>
    <Typography variant="body1" style={{color: '#FFFFFF'}} noWrap>
      Note: The dnergy dashboard is not finetuned to your current browser [detected as "{browser}"],<br/>
      For an optimal experience, we recommend using a recent version of<br/>
      <a href="https://chrome.google.com" target="_blank" rel="noopener noreferrer" style={{color: '#FFFFFF'}}>the Chrome browser</a><br/>
    </Typography>
  </div>,
  safari: (browser) =>
    <div>
      <Button variant="contained" onClick={(event) => handleAuth(event, false)}>
        Login using Auth0
      </Button>
      <br/><br/>
      <a href="#no-op" style={{color: '#FFFFFF', fontSize:'small'}} onClick={(event) => handleAuth(event, true)}>Login as ...</a>
    </div>,
  default: (browser) =>
    <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
      Apologies, the browser you're currently using [detected as "{browser}"] is not yet supported,<br/>
      please switch to a recent version of one of the following browsers:<br/>
      <br/>
      <a href="https://chrome.google.com" target="_blank" rel="noopener noreferrer" style={{color: '#FFFFFF'}}>Chrome (currently best supported)</a><br/>
      <a href="https://www.mozilla.org/firefox/" target="_blank" rel="noopener noreferrer" style={{color: '#FFFFFF'}}>Firefox</a><br/>
      <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer" style={{color: '#FFFFFF'}}>Safari</a><br/>
    </Typography>,
};

class TemplateLanding extends React.Component {
  render() {
    return (
      <div style={{minHeight: '100vh',
                  backgroundImage: 'url(/static/images/bg_dk-sq.jpg)',
                  fontFamily: 'montserrat',
                  zIndex: 1,
                  backgroundSize: 'cover',
                  overflow: 'hidden'}}>
        <main style={{flexGrow: 1, paddingTop: 100, textAlign: 'center'}}>
          <div><img alt="dnergy" src="/static/images/dnergy_color.png" width="50%" style={{width: '50%', maxWidth: 600}} /></div>
          <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
            &nbsp;
          </Typography>
          <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
            &nbsp;
          </Typography>
          <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
            &nbsp;
          </Typography>
          <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
            &nbsp;
          </Typography>
          <Typography variant="h5" style={{color: '#FFFFFF'}} noWrap>
            &nbsp;
          </Typography>
          <BrowserDetection>
            { browserHandler }
          </BrowserDetection>
        </main>
      </div>
    );
  }
}

export default TemplateLanding;
