import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import MuiForm from 'rjsf-material-ui';




const ConfigSteerMetrics = props => {
  const projectID = props.projectID
  const formulaType = props.formulaType
  const dataSteerMetrics = useQuery(getSteerMetrics, { variables:   {projectID} })
  const dataModelMetricTemplates = useQuery(getModelMetricTemplates, { variables:   {projectID} })
  const dataControlProtocolTemplates= useQuery(getControlProtocolTemplates, { variables:   {projectID} })
  const dataRawTrajectories = useQuery(getRawTrajectories, { variables:   {projectID} })
  const modelMetricTemplates = dataModelMetricTemplates.data?.modelMetricTemplates?[...new Set(dataModelMetricTemplates.data.modelMetricTemplates.map( obj => obj?.name? obj.name:undefined))]:[]
  const controlProtocolTemplates =  dataControlProtocolTemplates.data?.controlProtocolTemplates?[...new Set(dataControlProtocolTemplates.data.controlProtocolTemplates.map( obj => obj?.name? obj.name:undefined))]:[]
  const rawTrajectories = dataRawTrajectories.data?.rawTrajectories?dataRawTrajectories.data.rawTrajectories:[]
  const [ updateValueMapsMutation ] = useMutation(updateValueMaps)
  const [ updateSteerMetricMutation ] = useMutation(updateSteerMetric)
  const [ updateModelMetricPostProcessingsMutation ] = useMutation(updateModelMetricPostProcessings)
  const [ createModelMetricPostProcessingsMutation ] = useMutation(createModelMetricPostProcessings)

  if (dataSteerMetrics.loading) return (<Grid><CircularProgress size={50} color="secondary" /></Grid>)
  if (dataSteerMetrics.error) return (<Grid><Typography gutterBottom>Error steerMetrics data!</Typography></Grid>)
  if (!dataSteerMetrics.data.steerMetrics) return (<Typography gutterBottom>No steerMetrics Found!</Typography>)

  const getRawTrajectoryId = (tagname) => rawTrajectories.find((rt) => rt.tagname===tagname).id
  const isRawTrajectory = (tagname) => rawTrajectories.find((rt) => rt.tagname===tagname)?.id?true:false
  
  const handleSteerMetricFormSubmit = ({formData}) => {
    formData.RawTrajectories.filter(rt => isRawTrajectory(rt.tagname)).map((rt) => updateValueMapsMutation({
      variables: {
        id: rt.id?rt.id:getRawTrajectoryId(rt.tagname),
        valueMapRead: rt.valueMapRead,
        valueMapWrite: rt.valueMapWrite,
        controlProtocolTemplate: dataControlProtocolTemplates.data?.controlProtocolTemplates?dataControlProtocolTemplates.data.controlProtocolTemplates.find(tpl => tpl.name===rt.controlProtocolTemplate).id:null,
        clearingValue: rt.clearingValue
      },
      refetchQueries: [{
        query: getSteerMetrics,
        variables: { projectID: projectID }
      }],
    }).then(response => {

    }))
    var formulas = {}
    for (var i=0; i < formData.RawTrajectories.length; i++) {
      formulas[formData.RawTrajectories[i].tagname] = formData.RawTrajectories[i].formula === "Custom" ? formData.RawTrajectories[i].custom_formula : formData.RawTrajectories[i].formula
    }

   
    updateSteerMetricMutation({
      variables: {
        id: formData.id,
        rawTrajectories: formData.RawTrajectories.filter(rt => isRawTrajectory(rt.tagname)).map(rt => ({"id":rt.id?rt.id:getRawTrajectoryId(rt.tagname)})),
        modelMetricPostProcessings: formData.postProcessing.filter(pp => pp?.id?true:false).map(pp => ({"id":pp.id})),
        formulas: JSON.stringify(formulas)
      },
      refetchQueries: [{
        query: getSteerMetrics,
        variables: { projectID: projectID }
      }],
    }).then(response => {

    })
    // Disconnect
    //Update ModelMetricPostProcessing
    formData.postProcessing.map((processing) => {
      if (processing.id) {
        updateModelMetricPostProcessingsMutation({
          variables: {
            id: processing.id,
            formula: processing.formula === "Custom" ? processing.custom_formula : processing.formula,
            modelMetricTemplate_id: dataModelMetricTemplates.data.modelMetricTemplates.find(obj => obj.name===processing.modelMetricTemplate.name).id, 
            projectID: projectID
          },
          refetchQueries: [{
            query: getSteerMetrics,
            variables: { projectID: projectID }
          }],
        }).then(response => {

        })
      }

      else {
        createModelMetricPostProcessingsMutation({
          variables: {
            formula: processing.formula === "Custom" ? processing.custom_formula : processing.formula,
            modelMetricTemplate_id: dataModelMetricTemplates.data.modelMetricTemplates.find(obj => obj.name===processing.modelMetricTemplate.name).id,
            steerMetrics: [{'id':formData.id}],
            projectID: projectID
          },
          refetchQueries: [{
            query: getSteerMetrics,
            variables: { projectID: projectID }
          }],
        }).then(response => {

        })
      }

      return null
    }
    )
    
  
  }
  return (

      <ExpansionPanel key={"stm"}  TransitionProps={{ unmountOnExit: true }} defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>{"SteerMetrics"}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid style={{width: "100%"}}>
            {dataSteerMetrics.data.steerMetrics.map(stm => {
              const formulas = Object.entries(stm.formulas).filter(obj => stm.rawTrajectories.find(rt => rt.tagname === obj[0]) ? true : false).map(obj => {
                const rt_ = stm.rawTrajectories.find(rt => rt.tagname === obj[0])
                return {
                  "valueMapRead": JSON.stringify(rt_.valueMapRead),
                  "valueMapWrite": JSON.stringify(rt_.valueMapWrite),
                  "controlProtocolTemplate": rt_.controlProtocolTemplate?.name?rt_.controlProtocolTemplate.name:null,
                  "clearingValue": rt_?.clearingValue?rt_.clearingValue:null,
                  "tagname": obj[0], "formula": formulaType.includes(obj[1]) ? obj[1] : "Custom",
                  "custom_formula": formulaType.includes(obj[1]) ? "" : obj[1],
                  "id": stm.rawTrajectories.find(rt => rt.tagname === obj[0]).id
                }
              })
              
              stm.RawTrajectories = formulas
              const postProcessing = stm.modelMetricPostProcessings.map( obj => ({"id":obj?.id?obj.id:null,"modelMetricTemplate":obj.modelMetricTemplate, "formula":formulaType.includes(obj.formula)?obj.formula:"Custom", "custom_formula":formulaType.includes(obj.formula)?"":obj.formula}))
              stm.postProcessing = postProcessing
              return (<ExpansionPanel key={stm.displayName} defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography key="bn" variant="h6" gutterBottom>{stm.displayName}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <MuiForm schema={stmSchema(modelMetricTemplates,controlProtocolTemplates, formulaType)} formData={stm} onSubmit={(formData) => handleSteerMetricFormSubmit(formData)}>
                    <Button style={{ display: 'inline-block' }}  variant="contained" color="primary" type="submit"  >
                  Update 
                </Button>
                    </MuiForm>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
     
  );
}


const getSteerMetrics = gql`
query getSteerMetrics($projectID: String!) {
  steerMetrics (projectID:$projectID){
    id
    slug
    displayName
    modelMetrics{id modelMetricTemplate{name}}
    systemMetrics{id systemMetricTemplate{name}}
    rawTrajectories{id tagname valueMapRead valueMapWrite clearingValue controlProtocolTemplate{name}}
    sourceSystemMetric{id systemMetricTemplate{name}}
    modelMetricPostProcessings{id modelMetricTemplate{name} formula}
    formulas
  }
}`;

const getModelMetricTemplates = gql`
query getModelMetricTemplates($projectID: String!) {
  modelMetricTemplates (projectID:$projectID){
    id
    name
  }
}`;

const getRawTrajectories = gql`
query getRawTrajectories($projectID: String!) {
  rawTrajectories (projectID:$projectID){
    id
    tagname
    valueMapRead
    valueMapWrite
    clearingValue
    controlProtocolTemplate{name}
  }
}`;

const getControlProtocolTemplates = gql`
query getControlProtocolTemplates($projectID: String!) {
  controlProtocolTemplates (projectID:$projectID){
    id
    name
  }
}`;


const updateValueMaps = gql`
mutation updateValueMaps($id: ID!, $valueMapRead: String, $valueMapWrite: String, $controlProtocolTemplate: ID, $clearingValue: String) {
  updateValueMaps(
    id: $id
    valueMapWrite: $valueMapWrite
    valueMapRead: $valueMapRead
    controlProtocolTemplate: $controlProtocolTemplate
    clearingValue: $clearingValue
  ) { id }
}`;

const updateSteerMetric = gql`
mutation updateSteerMetric($id: ID!, $rawTrajectories: [RawTrajectoryWhereUniqueInput!]!, $modelMetricPostProcessings: [ModelMetricPostProcessingWhereUniqueInput!]!, $formulas: String!) {
  updateSteerMetric(
    id: $id
    rawTrajectories: $rawTrajectories
    modelMetricPostProcessings: $modelMetricPostProcessings
    formulas: $formulas
  ) { id }
}`;

const updateModelMetricPostProcessings = gql`
mutation updateModelMetricPostProcessings($id: ID!, $formula: String!, $modelMetricTemplate_id: ID!, $projectID: String!) {
  updateModelMetricPostProcessings(
    id: $id
    formula: $formula
    modelMetricTemplate_id: $modelMetricTemplate_id
    projectID: $projectID
  ) { id }
}`;

const createModelMetricPostProcessings = gql`
mutation createModelMetricPostProcessings($formula: String!, $modelMetricTemplate_id: ID!, $steerMetrics: [SteerMetricWhereUniqueInput!], $projectID: String!) {
  createModelMetricPostProcessings(
    steerMetrics: $steerMetrics
    formula: $formula
    modelMetricTemplate_id: $modelMetricTemplate_id
    projectID: $projectID
  ) { id }
}`;




const stmSchema = (modelMetricTemplates, controlProtocolTemplates, formulaType) => {

  const formulaTypeOriginal = [...formulaType]
  const formulaTypeReduced = [...formulaType]
  formulaTypeReduced.shift()

  return {
    "type": "object",
    "properties": {
      "slug": {
        "type": "string"
      },
      "RawTrajectories": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "tagname": {
              "type": "string",
            },
            "valueMapRead": {
              "type": "string",
            },
            "valueMapWrite": {
              "type": "string",
            },
            "controlProtocolTemplate": {
              "type": "string",
              "enum": controlProtocolTemplates
            },
            "clearingValue": {
              "type": "string"
            },
            "formula": {
              "type": "string",
              "enum": formulaTypeOriginal
            },
          },
          "dependencies": {
            "formula": {
              "oneOf": [
                {
                  "properties": {
                    "formula": {
                      "enum": formulaTypeReduced
                    }
                  }
                },
                {
                  "properties": {
                    "formula": {
                      "enum": [
                        "Custom"
                      ]
                    },
                    "custom_formula": {
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        }
      },

      "postProcessing": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "formula": {
              "type": "string",
              "enum": formulaTypeOriginal
            },
            "modelMetricTemplate": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string",
                  "enum": modelMetricTemplates
                }
              }
            }
          },
          "dependencies": {
            "formula": {
              "oneOf": [
                {
                  "properties": {
                    "formula": {
                      "enum": formulaTypeReduced
                    }
                  }
                },
                {
                  "properties": {
                    "formula": {
                      "enum": [
                        "Custom"
                      ]
                    },
                    "custom_formula": {
                      "type": "string"
                    }
                  }
                }
              ]
            }
          }
        }
      }
    }
  }
}

export default ConfigSteerMetrics;