import React from 'react';
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const UploadBuildingImage = ({ mutate, projectID }) => {
  const handleChange = ({
    target: {
      validity,
      files: [file]
    }
  }) =>
    validity.valid &&
    mutate({
      variables: { projectID, file },
      refetchQueries: [{
          query: updateBuildingCache,
          variables: { projectID },
      }],
    })

  return <input type="file" required onChange={handleChange} />
}

const updateBuildingCache = gql`
  query updateCache($projectID:String!) {
    building(where: {projectID: $projectID}) {
      id
      image {
        id
        file { id url }
      }
    }
  }
`
export default graphql(gql`
  mutation($projectID: String!, $file: Upload!) {
    uploadBuildingImage(projectID: $projectID, file: $file) {
      id
    }
  }
`)(UploadBuildingImage)
