import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import LogBookEntryList from './LogBookEntryList'

const LogBookTable = props => {
    const { loading, error, data } = useQuery(LogBookQuery, { variables: { projectID: props.projectID }})

    if (loading) return (<Paper style={{ padding: 25 }}><CircularProgress size={50} color="secondary" /></Paper>)
    if (error) return (<Paper style={{ padding: 25 }}><Typography>An error occurred - log data not available</Typography></Paper>)
    if (!(data.building && data.building.logEntries)) 
        return (<Paper style={{ padding: 25 }}><Typography>No log data found</Typography></Paper>)

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>start</TableCell>
                    <TableCell>end</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <LogBookEntryList
                entries={data.building.logEntries}
                projectID={props.projectID}
            />
        </Table>        
    );
}

const LogBookQuery = gql`
query LogBookQuery($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id displayName
        logEntries {
            id name description start end
            logTags { id name }
        }
    }
}`

export default LogBookTable;
