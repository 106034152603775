//****************************************************************************************************
// Imports
//****************************************************************************************************

//================================================================================
// External
//================================================================================

import React, {useEffect, useState} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//================================================================================
// Internal
//================================================================================
import ChartHistoryId from './ChartHistoryId';
import {useQuery} from "@apollo/react-hooks";
import {useSelectedBuilding} from "../contexts/SelectedBuilding";

//****************************************************************************************************
// OperationSpace
//****************************************************************************************************

//================================================================================
// Styling
//================================================================================

const styles = theme => ({
    whiteTextField: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
        marginBottom: '8px',
    },
    whiteMenu: {},
    whiteInput: {
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        hover: {
            height: '0px',
            backgroundColor: '#FFFFFF',
        }
    },
    whiteSelect: {
        backgroundColor: '#FFFFFF',
    },
});

//================================================================================
// Recursive Temperature Limit Retrieval
//================================================================================

const getFunctionFromTags = (tags) => {
    return tags.filter(tag => tag.key === "function")[0].value;
}

const filterForTemperatureLimits = (systemMetrics) => {
    return systemMetrics.filter(sm => (sm.systemMetricTemplate.name === "space_temperature_air" && (getFunctionFromTags(sm.systemMetricTags) === "limit_low" || getFunctionFromTags(sm.systemMetricTags) === "limit_high")));
}

const getParentTemperatureLimits = (spaces, selectedSpace) => { // Recursively get the correct (parent) space that defines the 'limit_low/high' for the 'space_temperature_air' system-metric
    let temperatureLimitMetrics = filterForTemperatureLimits(selectedSpace.systemMetrics);
    //--- Limits are missing ---
    if (temperatureLimitMetrics.length === 0 && selectedSpace.slug !== "space_0000") {
        const parentSlug = selectedSpace.parentSpace ? selectedSpace.parentSpace.slug : "space_0000"; // Default to 'BuildingSpace' if not parent is defined
        const parentSpace = spaces.find(s => s.slug === parentSlug);
        if (parentSpace) temperatureLimitMetrics = temperatureLimitMetrics.concat(getParentTemperatureLimits(spaces, parentSpace));
    }
    return temperatureLimitMetrics;
}

//================================================================================
// Main Component
//================================================================================

const OperationSpace = props => {
    const {classes} = props;

    const [selectedSpaceSlug, setSelectedSpaceSlug] = useState("");
    const {selectedProjectID} = useSelectedBuilding();

    const {data, loading, error} = useQuery(getCurrentOperationSpaces, {variables: {projectID: selectedProjectID}});


    const selectedBuildingWithSpaces = data?.building;
    const spaces = selectedBuildingWithSpaces?.spaces || [];

    useEffect(() => {
        setSelectedSpaceSlug("");
    }, [selectedProjectID]);

    useEffect(() => {
        if (spaces.length > 0) {
            setSelectedSpaceSlug(spaces[0].slug);
        }
    }, [selectedBuildingWithSpaces, spaces]);


    const selectedSpace = spaces.find(s => s.slug === selectedSpaceSlug);
    const extendedSystemMetrics = selectedSpace
        ? [...selectedSpace.systemMetrics, ...getParentTemperatureLimits(spaces, selectedSpace)]
        : [];

    const systemMetricIdList = [
        ...extendedSystemMetrics.map(sm => sm.id),
        ...selectedSpace?.systems.flatMap(s => s.systemMetrics.map(sm => sm.id)) || []
    ];

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;


    return (
        <div>
            <Paper style={{padding: 25}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            id="select-space"
                            select
                            value={selectedSpaceSlug}
                            onChange={(event) => setSelectedSpaceSlug(event.target.value)}
                            margin="normal"
                            className={classes.whiteTextField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.whiteMenu,
                                },
                            }}
                            inputProps={{
                                className: classes.whiteInput,
                            }}
                        >
                            {selectedBuildingWithSpaces?.spaces
                                ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
                                .map(option => (
                                    <MenuItem key={option.slug} value={option.slug}>
                                        {option.displayName}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ChartHistoryId
                            systemMetricIds={systemMetricIdList}
                            projectID={selectedProjectID}
                            systemMetricGroups={[
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "limit_high",
                                    "axis": "temperature_air",
                                    "color": "#4393c3",
                                    "dashed": true,
                                    "addToLegend": true,
                                },
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "percentile_high",
                                    "axis": "temperature_air",
                                    "color": "red",
                                    "dashed": true,
                                    "addToLegend": true,
                                    "systemMetricTagTimewindow": "past"
                                },
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "sensor",
                                    "axis": "temperature_air",
                                    "color": "red",
                                    "addToLegend": true,
                                },
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "setpoint",
                                    "axis": "temperature_air",
                                    "color": "red",
                                    "dashed": true,
                                    "addToLegend": true,
                                },
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "percentile_low",
                                    "axis": "temperature_air",
                                    "color": "red",
                                    "dashed": true,
                                    "addToLegend": true,
                                    "systemMetricTagTimewindow": "past"
                                },
                                {
                                    "systemMetricTemplateName": "space_temperature_air",
                                    "systemMetricTagFunction": "limit_low",
                                    "axis": "temperature_air",
                                    "color": "#1a9850",
                                    "dashed": true,
                                    "addToLegend": true,
                                },

                                {
                                    "systemMetricTemplateName": "vrv_status",
                                    "systemMetricTagFunction": "setpoint",
                                    "axis": "status",
                                    "color": "#763F08",
                                    "addToLegend": true,
                                },
                                {
                                    "systemMetricTemplateName": "vrv_operation_mode",
                                    "systemMetricTagFunction": "setpoint",
                                    "axis": "status",
                                    "color": "#649E2A",
                                    "addToLegend": true,
                                },
                                {
                                    "systemMetricTemplateName": "vrv_fan_speed",
                                    "systemMetricTagFunction": "setpoint",
                                    "axis": "status",
                                    "color": "#575AB6",
                                    "addToLegend": true,
                                },
                            ]}
                            systemMetricAxes={[
                                {
                                    "name": "status",
                                    "label": "Status",
                                },
                                {
                                    "name": "temperature_air",
                                    "label": "Air temperature [°C]",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// GraphQL Query
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const getCurrentOperationSpaces = gql`
query getCurrentOperationSpaces($projectID: String!) {
  me {
    id
    email
    name
    role
  }
  building(where: {projectID: $projectID}) {
      id
      projectID
      lastDataPushDateTime
      spaces {
        id
        displayName
        slug
        parentSpace {
          id
          slug
        }
        systemMetrics(where:{systemMetricTemplate:{name:"space_temperature_air"}}) {
          id
          systemMetricTemplate {
            id
            name
          }
          systemMetricTags{
            key
            value
          }
        }
        systems (where:{systemTemplate:{name:"systemTemplate_vrv"}}) {
          id
          displayName
          slug
          systemTemplate {
            id
            name
          }
          systemMetrics {
            id
            systemMetricTemplate {
              id
              name
            }
          }
        }
      }
    }
}`;

export default withStyles(styles)(OperationSpace)
