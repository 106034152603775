export function computeStatusColor(controlStatus, controlStatusSetpoint, me, building) {

    let activationDateSteering = building.activationDateSteering;

    if (controlStatus === "Unknown") {
        return me.client.colorStateUndefined;
    }

    if (controlStatusSetpoint !== controlStatus) {
        if (controlStatusSetpoint === "Active") {
            return me.client.colorStateActivating;
        } else {
            return me.client.colorStateDeactivating;
        }
    // controlStatus is Active and controlStatusSetpoint is Active
    } else if (controlStatus === "Active") {
        return me.client.colorStateActive;
        // controlStatus is not Active and controlStatusSetpoint is not Active but it was once activated for steering
    } else {
        if (activationDateSteering !== null) {
            return me.client.colorStateInactive;
        }
    }
    return me.client.colorStateUndefined;
}