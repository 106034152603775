import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import ChartHistory from './ChartHistory';
import ChartHistoryOutgoingWaterCircuits from './ChartHistoryOutgoingWaterCircuits';

const OperationHVAC = props => {
  const { projectID } = props.match.params
  return (
    <div>
      <Paper style={{ padding: 25 }}>
        <Typography variant="subtitle1">Heating power and water supply temperature</Typography>
        <Typography>&nbsp;</Typography>
        <Grid container>
          <Grid item xs={12}>
            <ChartHistory
              projectID={projectID}
              systemMetricGroups={[
                {
                  "systemTemplateName": "systemTemplate_hp",
                  "systemMetricTemplateName": "hp_power_thermal",
                  "systemMetricTagFunction": "sensor",
                  "axis": "power_thermal",
                  "color": "*",
                  "addToLegend": true,
                },
                {
                  "systemTemplateName": "systemTemplate_hp",
                  "systemMetricTemplateName": "hp_temperature_water_supply",
                  "systemMetricTagFunction": "sensor",
                  "axis": "temperature_water",
                  "color": "Paired",
                  "addToLegend": true,
                },
              ]}
              systemMetricAxes={[
                {
                  "name": "power_thermal",
                  "label": "Thermal power [kW]",
                },
                {
                  "name": "temperature_water",
                  "label": "Water temperature [°C]",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Paper>
      <Typography>&nbsp;</Typography>
      <ChartHistoryOutgoingWaterCircuits
        projectID={projectID}
        systemTemplateNames={["systemTemplate_collector"]}
        systemMetricTemplateNames={["wc_power_thermal", "wc_temperature_water_supply"]}
      />
    </div>
  )
}

export default OperationHVAC
