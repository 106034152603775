import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import UploadBuildingImage from './UploadBuildingImage';
import { useQuery, useMutation } from '@apollo/react-hooks';

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const INPUT_RULES = {
  surfaceM2: [0, 500000],
}

const BUILDING = gql`
query Building ($projectID: String) {
  building (where: {projectID: $projectID}) {
    id
    projectID
    description
    contactName
    contactEmail
    displayName
    description
    descriptionAssetManager
    descriptionSystems
    descriptionBMS
    surfaceM2
    activationDate
    activationDateDashboard
    activationDateSteering
    activationDateSavings
    lastDataPushDateTime
    location {
      id
      address
      city
      country
    }
    image {
      id
      file {
        id
        url
      }
    }
  }
}`

const deleteBuildingImage = gql`
mutation deleteBuildingImage($buildingImageId: ID!) {
  deleteBuildingImage (
    id: $buildingImageId
  ) {
    id
  }
}`;

const updateBuilding = gql`
mutation updateBuilding(
    $projectID: String!,
    $contactName: String,
    $contactEmail: String,
    $displayName: String,
    $description: String,
    $descriptionAssetManager: String,
    $descriptionSystems: String,
    $descriptionBMS: String,
    $surfaceM2: Int,
    $activationDate: String,
    $activationDateDashboard: String,
    $activationDateSteering: String,
    $activationDateSavings: String,
  ) {
  updateBuildingDetails (
    projectID: $projectID
    contactName: $contactName
    contactEmail: $contactEmail
    displayName: $displayName
    description: $description
    descriptionAssetManager: $descriptionAssetManager
    descriptionSystems: $descriptionSystems
    descriptionBMS: $descriptionBMS
    surfaceM2: $surfaceM2
    activationDate: $activationDate
    activationDateDashboard: $activationDateDashboard
    activationDateSteering: $activationDateSteering
    activationDateSavings: $activationDateSavings
  ) { id }
}`;

const updateLocation = gql`
mutation updateLocation (
    $id: ID!,
    $address: String,
    $city: String,
    $country: String
  ) {
  updateLocation(
    id: $id
    address: $address
    city: $city
    country: $country
  ) { id }
}`;

const DataInputBuilding = props => {
  const [ buildingImageId, setBuildingImageId ] = useState(null)
  const [ buildingImageUrl, setBuildingImageUrl ] = useState(null)
  const [ form, setForm ] = useState({})
  const [ formProjectID, setFormProjectID ] = useState(null)
  const { classes } = props;

  const { data, loading, error, refetch } = useQuery(BUILDING, { variables: { projectID: props.match.params.projectID }})
  const [ updateBuildingMutation ] = useMutation(updateBuilding)
  const [ updateLocationMutation ] = useMutation(updateLocation)
  const [ deleteBuildingImageMutation ] = useMutation(deleteBuildingImage)

  const input = value => {
    return {
      value,
      isValid: true,
    }
  }

  if (loading) return (<CircularProgress size={50} color="secondary" />)
  if (error) return (<Typography gutterBottom>Error fetching Building data!</Typography>)

  const newForm = {}

  const { building } = data
  if (building.image && building.image.id && building.image.id !== buildingImageId) {
    setBuildingImageId(building.image.id)
    setBuildingImageUrl(building.image.file.url)
  }
  if (buildingImageId && (!building.image || !building.image.id)) {
    setBuildingImageId(null)
    setBuildingImageUrl(null)
  }

  const buildingFields = ["contactName", "contactEmail", "displayName", "description", "descriptionAssetManager", "descriptionSystems", "descriptionBMS", "surfaceM2", "activationDate", "activationDateDashboard", "activationDateSteering", "activationDateSavings"]

  buildingFields.forEach((fieldName) => {
    const value = building[fieldName]
    newForm[fieldName] = input(value ? value : "")
  })

  const addressFields = ["address", "city", "country"]

  addressFields.forEach((fieldName) => {
    const value = building.location[fieldName]
    newForm[fieldName] = input(value ? value : "")
  })

  if (formProjectID !== building.projectID) {
    setForm(newForm)
    setFormProjectID(building.projectID)
    return ''
  }

  const formIsValid = () => (
    Object.keys(form)
            .map(fieldName => form[fieldName].isValid)
            .filter(b => !b)
            .length === 0
  )

  const handleBuildingImageDelete = async () => {
    await deleteBuildingImageMutation({variables: { buildingImageId }})
    await refetch()
  }

  const handleChange = name => event => {
    const newForm = { ...form }
    newForm[name].value = event.target.value
    if (name in INPUT_RULES) {
      const [min, max] = INPUT_RULES[name];
      const value = event.target.value;
      newForm[name].isValid = (value >= min && value <= max);
    }
    setForm({ ...newForm })
  };

  const handleSave = async () => {
    await updateBuildingMutation({variables: {
      projectID: building.projectID,
      contactName: form.contactName.value,
      contactEmail: form.contactEmail.value,
      displayName: form.displayName.value,
      description: form.description.value,
      descriptionAssetManager: form.descriptionAssetManager.value,
      descriptionSystems: form.descriptionSystems.value,
      descriptionBMS: form.descriptionBMS.value,
      surfaceM2: parseInt(form.surfaceM2.value, 10),
      activationDate: form.activationDate.value === "" ? null : form.activationDate.value,
      activationDateDashboard: form.activationDateDashboard.value === "" ? null : form.activationDateDashboard.value,
      activationDateSteering: form.activationDateSteering.value === "" ? null : form.activationDateSteering.value,
      activationDateSavings: form.activationDateSavings.value === "" ? null : form.activationDateSavings.value,
    }})
    if (building.location) {
      await updateLocationMutation({variables: {
        id: building.location.id,
        address: form.address.value,
        city: form.city.value,
        country: form.country.value,
      }})
    }
    await refetch()
  }

  if (error) {
    return (<Typography gutterBottom>Error fetching form data!</Typography>)
  };

  if (loading) {
      return (<CircularProgress size={50} color="secondary" />)
  };

  if (!building.projectID) {
      return (<Typography gutterBottom>No data available</Typography>)
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.displayName.value} onChange={handleChange("displayName")} />
                <FormHelperText>Building Display Name</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.contactName.value} onChange={handleChange("contactName")} />
                <FormHelperText>Contact Name</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.contactEmail.value} onChange={handleChange("contactEmail")} />
                <FormHelperText>Contact Email</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.address.value} onChange={handleChange("address")} />
                <FormHelperText>Street and number</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.city.value} onChange={handleChange("city")} />
                <FormHelperText>City</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.country.value} onChange={handleChange("country")} />
                <FormHelperText>Country</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
                <Input value={form.description.value} onChange={handleChange("description")} />
                <FormHelperText>Building Description</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input value={form.descriptionBMS.value} onChange={handleChange("descriptionBMS")} />
                <FormHelperText>Building Management System type and version</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input multiline rows={2} value={form.descriptionSystems.value} onChange={handleChange("descriptionSystems")} />
                <FormHelperText>Technical Systems (multiline)</FormHelperText>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <Input multiline rows={2} value={form.descriptionAssetManager.value} onChange={handleChange("descriptionAssetManager")} />
                <FormHelperText>Owner / Asset Manager (multiline)</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Input
                  error={!form.surfaceM2.isValid}
                  value={form.surfaceM2.value}
                  onChange={handleChange("surfaceM2")} />
                <FormHelperText>Building surface [m<sup>2</sup>]</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
              <Input placeholder="YYYY-MM-DD" value={form.activationDateDashboard?.value} onChange={handleChange("activationDateDashboard")} />
                <FormHelperText>Activation Date Dashboard</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Input placeholder="YYYY-MM-DD" value={form.activationDateSteering?.value} onChange={handleChange("activationDateSteering")} />
                <FormHelperText>Activation Date Steering</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Input placeholder="YYYY-MM-DD" value={form.activationDateSavings?.value} onChange={handleChange("activationDateSavings")} />
                <FormHelperText>Activation Date Savings</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center'}}>
              <Button className={classes.button} variant="contained" style={{margin:"auto"}} onClick={handleSave}
                disabled={!formIsValid()}>
                <Icon className={classes.leftIcon}>save</Icon>
                Save Building Characteristics
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6">
                &nbsp;
              </Typography>
              <Grid container>
                <Grid item style={{textAlign:"center"}}>
                  {buildingImageUrl ? (
                    <div>
                      <img alt="Building Visual" src={buildingImageUrl} style={{backgroundColor:"#999999", maxWidth:"330px", maxHeight:"330px"}} />
                      <br /><br />
                      <Button variant="contained" style={{margin:"auto"}} onClick={handleBuildingImageDelete}>
                        <Icon className={classes.leftIcon}>delete</Icon>
                        Delete Picture
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography>
                        Upload a building image for {form.displayName.value}
                      </Typography>
                      <Typography>
                        &nbsp;
                      </Typography>
                      <UploadBuildingImage projectID={props.match.params.projectID} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(DataInputBuilding)
