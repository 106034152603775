import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import ChartHistoryWithForecast from './ChartHistoryWithForecast';

const OperationModel = props => {
  const { projectID } = props.match.params
  return (
    <div>
      <Paper style={{ padding: 25 }}>
        <Typography variant="subtitle1">Heating power and water supply temperature</Typography>
        <Typography>&nbsp;</Typography>
        <Grid container>
          <Grid item xs={12}>
            <ChartHistoryWithForecast
              legendGrouping="system"
              projectID={projectID}
              systemMetricGroups={[
                {
                  "systemTemplateName": "systemTemplate_cm",
                  "systemMetricTemplateName": "cm_power_thermal_heating",
                  "axis": "power_thermal",
                  "color": "#575AB6",
                  "addToLegend": true,
                },
                {
                  "systemTemplateName": "systemTemplate_cm",
                  "systemMetricTemplateName": "cm_temperature_water_supply_heating",
                  "axis": "temperature_water",
                  "color": "#649E2A",
                  "addToLegend": true,
                },
              ]}
              systemMetricAxes={[
                {
                  "name": "power_thermal",
                  "label": "Thermal power [kW]",
                },
                {
                  "name": "temperature_water",
                  "label": "Water temperature [°C]",
                },
              ]} />
          </Grid>
        </Grid>
      </Paper>
      <Typography>&nbsp;</Typography>
      <Paper style={{ padding: 25 }}>
        <Typography variant="subtitle1">Cooling power and water supply temperature</Typography>
        <Typography>&nbsp;</Typography>
        <Grid container>
          <Grid item xs={12}>
            <ChartHistoryWithForecast
              legendGrouping="system"
              projectID={projectID}
              systemMetricGroups={[
                {
                  "systemTemplateName": "systemTemplate_cm",
                  "systemMetricTemplateName": "cm_power_thermal_cooling",
                  "axis": "power_thermal",
                  "color": "#575AB6",
                  "addToLegend": true,
                },
                {
                  "systemTemplateName": "systemTemplate_cm",
                  "systemMetricTemplateName": "cm_temperature_water_supply_cooling",
                  "axis": "temperature_water",
                  "color": "#649E2A",
                  "addToLegend": true,
                },
              ]}
              systemMetricAxes={[
                {
                  "name": "power_thermal",
                  "label": "Thermal power [kW]",
                },
                {
                  "name": "temperature_water",
                  "label": "Water temperature [°C]",
                },
              ]} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default OperationModel
