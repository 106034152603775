import React, {Component} from 'react'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

//import {Sigma, SigmaEnableSVG, EdgeShapes, RandomizeNodePositions, Dagre, RelativeSize} from 'react-sigma';
//import ForceLink from 'react-sigma/lib/ForceLink';

class BuildingSchema extends Component {
    render() {
        if (this.props.data.error) {
            return (<Typography gutterBottom>Error fetching form data!</Typography>)
        };
        if (this.props.data.loading) {
            return (<CircularProgress size={50} color="secondary" />)
        };
        if (!this.props.data.me) {
            return (<Typography gutterBottom>Rendering form ...</Typography>)
        };
        if (this.props.data.me.client.buildings.length === 0) {
            return (<Typography gutterBottom>No data available</Typography>)
        } else {
            const building = this.props.data.me.client.buildings[0]
            // const spaceNodes = building.spaces.map((space)=>({id:space.id, label:space.displayName, size:"5"}));
            // const spaceSpaceEdges = building.spaces.filter((space)=>(space.parentSpace!=null)).map((space, index)=>({id:'spsp'+index, source:space.parentSpace.id, target:space.id}));
            // const systemNodes = building.systems.map((system)=>({id:system.id, label:system.displayName, size:"4", color:"#00CC00"}));
            // const spaceSystemEdges = building.spaces.filter((space)=>(space.systems.length>0)).map((space, index)=>({id:'spsy'+index, source:space.id, target:space.systems[0].id, color:"#00CC00"}));
            // const waterCircuitEdges = building.waterCircuits.filter((waterCircuit)=>(waterCircuit.sourceSystems.length>0&&waterCircuit.destinationSystems.length>0)).map((waterCircuit, index)=>({id:'wade'+index, label:waterCircuit.slug, source:waterCircuit.sourceSystems[0].id, target:waterCircuit.destinationSystems[0].id, color:"#0000CC"}));
            // const spaceGraphData = {
            //     nodes: spaceNodes.concat(systemNodes),
            //     edges: spaceSpaceEdges.concat(spaceSystemEdges).concat(waterCircuitEdges),
            // };
            // const waterCircuitNodes = building.waterCircuits.map((waterCircuit)=>({id:waterCircuit.id, label:waterCircuit.displayName, color:"#0000CC"}));
            // const waterCircuitEdgesSource = building.waterCircuits.filter((waterCircuit)=>(waterCircuit.sourceSystems.length>0)).map((waterCircuit, index)=>({id:'waso'+index, source:waterCircuit.sourceSystems[0].id, target:waterCircuit.id, color:"#0000CC"}));
            // const waterCircuitEdgesDestination = building.waterCircuits.filter((waterCircuit)=>(waterCircuit.destinationSystems.length>0)).map((waterCircuit, index)=>({id:'wade'+index, source:waterCircuit.id, target:waterCircuit.destinationSystems[0].id, color:"#0000CC"}));
            // const spaceGraphData = {
            //    nodes: spaceNodes.concat(systemNodes).concat(waterCircuitNodes),
            //    edges: spaceSpaceEdges.concat(spaceSystemEdges).concat(waterCircuitEdgesSource).concat(waterCircuitEdgesDestination),
            // };
            return (
                <Paper style={{ padding: 25 }}>
                    <Grid container>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography variant="h6">
                                {building.displayName}
                            </Typography>
                            <Typography>&nbsp;</Typography>
                            <img
                                src={building.image ? building.image.file.url: "/static/images/cards/generic_building_image.png"}
                                alt={building.displayName}
                                width="350"
                                style={{borderRadius: "10px"}}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
{/*                             <Sigma graph={spaceGraphData} settings={{defaultLabelSize: "8"}} renderer="svg">
                                <EdgeShapes default={"arrow"} />
                                <RandomizeNodePositions/>
                                <ForceLink background easing="cubicInOut"/>
                            </Sigma>
 */}                        </Grid>
                    </Grid>
                </Paper>
            );
        }
    }
}

const getCurrentUser = gql`
query getCurrentUser ($projectID: String!) {
    me {
        id
        client {
            id
            buildings (where: { projectID: $projectID}) {
                id
                projectID
                displayName
                image {
                  id
                  file {
                    id
                    url
                  }
                }
                spaces {
                    id
                    displayName
                    systemTemplate {
                        id
                        name
                        displayName
                    }
                    childSpaces {
                        id
                    }
                    parentSpace {
                        id
                    }
                    systems {
                        id
                    }
                    zones {
                        id
                    }
                }
                systems {
                    id
                    displayName
                    systemTemplate {
                        id
                        name
                        displayName
                    }
                    incomingWaterCircuits {
                        id
                    }
                    outgoingWaterCircuits {
                        id
                    }
                    spaces {
                        id
                    }
                    zones {
                        id
                    }
                }
                waterCircuits {
                    id
                    displayName
                    systemTemplate {
                        id
                        name
                        displayName
                    }
                    sourceSystems {
                        id
                    }
                    destinationSystems {
                        id
                    }
                    zones {
                        id
                    }
                }
                zonings {
                    id
                    slug
                    displayName
                    zones {
                        id
                        slug
                        displayName
                    }
                }
            }
        }
    }
}`

export default graphql(getCurrentUser, {
    options: (props) => ({ variables: { projectID: props.match.params.projectID } })
  })(BuildingSchema);
