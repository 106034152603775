import React from 'react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import ConfigZone from './ConfigZone'

class ConfigZoning extends React.Component {
  state = {
    displayName: "",
  }

  _reinitialize_state = () => {
    this.setState({ displayName: "" });
  }

  handleDisplayNameChange = event => {
    this.setState({ displayName: event.target.value })
  }
  
  handleZoneAdd = event => {
    this.props.createZoneMutation({
        variables: {
          zoningId: this.props.zoning.id,
          displayName: this.state.displayName,
        },
        refetchQueries: [{
          query: updateZoningCache,
          variables: {
            zoningId: this.props.zoning.id,
          }
        }],
      }).then(({ data }) => {
        console.log('got data', data);
      }).catch((error) => {
        console.log('there was an error sending the query', error);
      });
    this._reinitialize_state();
  }

  render() {
    return (
      <ExpansionPanel key={this.props.zoning.id} defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>Zone List</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid>
            {this.props.zoning.zones && this.props.zoning.zones.map((zone, index) => {
              return (
                <ConfigZone
                  key={zone.id}
                  zone={zone}
                  zoningId={this.props.zoning.id}
                  spaces={this.props.spaces} />
              )
            })}
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <FormControl>
            <Input
              id="displayName-input"
              value={this.state.displayName}
              onChange={this.handleDisplayNameChange}
            />
          </FormControl>
          <Button size="small" color="primary" onClick={this.handleZoneAdd} >
            Add New Zone
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

const createZone = gql`
mutation createZone($zoningId: ID!, $displayName: String!) {
  createZone(
    displayName: $displayName
    zoningId: $zoningId
  ) {
    id
    displayName
  }
}`;

const updateZoningCache = gql`
query updateZoningCache($zoningId: ID!) {
  zonings (id: $zoningId) {
    id
    displayName
    zones {
      id
      displayName
      spaces {
        id
        displayName
      }
    }
  }
}`;

export default graphql(createZone, { name: 'createZoneMutation' })(ConfigZoning);
