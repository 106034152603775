import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import moment from 'moment';

import 'react-vis/dist/style.css'
import {XYPlot, XAxis, YAxis, Hint, VerticalRectSeries, LabelSeries} from 'react-vis';

import {DropDownYearCalendar} from './DropDownYearCalendar'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
  },
});

const monthNamesDict = {1:"January", 2:"February", 3:"March", 4:"April", 5:"May", 6:"June", 7:"July", 8:"August", 9:"September", 10:"October", 11:"November", 12:"December"}
const daysOfTheWeek = {1:"Monday", 2:"Tuesday", 3:"Wednesday", 4:"Thursday", 5:"Friday", 6:"Saturday", 7:"Sunday"}

const sortProfileDayTypes = profileDayTypes => (
  Array.from(profileDayTypes).sort((a, b) => (a.priority < b.priority ? 1 : -1))
);

const checkDateProfileActive = (date, profileDayType) => (
  (Array.from(profileDayType.yearDays).indexOf(date.format("YYYY-MM-DD")) >= 0) ||
  (Array.from(profileDayType.weekDays).indexOf(date.isoWeekday()) >= 0)
);

const getDateProfile = (date, sortedProfileDayTypes) => {
  let result = sortedProfileDayTypes[sortedProfileDayTypes.length-1];
  for (var i = 0; i < sortedProfileDayTypes.length - 1; i++) {
    if (checkDateProfileActive(date, sortedProfileDayTypes[i])) {
      result = sortedProfileDayTypes[i];
      break;
    }
  }
  return result;
}

const getYearData = (year, sortedProfileDayTypes) => {
  let date = moment({ year:year, month:0, day:1 });
  let yearProfileList = [];
  const rowFirstDay = date.isoWeekday() - 1; //zero-based row numbers, Monday in top row
  while (date.year() === year) {
    yearProfileList.push({
      date: date,
      dateString: date.format('YYYY-MM-DD'),
      dateHint: daysOfTheWeek[date.isoWeekday()] + " " + date.format('YYYY-MM-DD') + " [" + (getDateProfile(date, sortedProfileDayTypes) ? getDateProfile(date, sortedProfileDayTypes).displayName : "") + "]",
      dateHintTitle: daysOfTheWeek[date.isoWeekday()] + " " + date.format('YYYY-MM-DD'),
      dateHintValue: getDateProfile(date, sortedProfileDayTypes) ? getDateProfile(date, sortedProfileDayTypes).displayName : "",
      week: Math.floor((date.dayOfYear() + rowFirstDay - 1) / 7),
      weekday: date.isoWeekday(),
      year: date.year(),
      dateType: getDateProfile(date, sortedProfileDayTypes),
      color: getDateProfile(date, sortedProfileDayTypes) ? getDateProfile(date, sortedProfileDayTypes).color : "#CCCCCC",
      x0: Math.floor((date.dayOfYear() + rowFirstDay - 1) / 7) + date.month() - 0.4,
      x: Math.floor((date.dayOfYear() + rowFirstDay - 1) / 7) + date.month() + 0.4,
      y0: 7-date.isoWeekday()-0.4,
      y: 7-date.isoWeekday()+0.4,
      // For the calendar cell label series
      label: date.format('D'),
      style: {fontSize: 10},
      xOffset: -8,
      yOffset: 11,
    });
    date.add(1, 'days');
  };
  return yearProfileList;
}

const ConfigComponentCalendar = props => {
  const [activeYear, setActiveYear] = useState((new Date()).getFullYear())
  const [sortedProfileDayTypes, setSortedProfileDayTypes] = useState([])
  const [yearData, setYearData] = useState([])
  const [selectedProfileDayType, setSelectedProfileDayType] = useState(null)
  const [hoveredCell, setHoveredCell] = useState(false)

  const hasProfileCalendar = Boolean(props.data
      && !props.data.error
      && !props.data.loading
      && props.data.building
      && props.data.building.profileCalendar)

  const hasProfileDayTypes = Boolean(hasProfileCalendar &&
      props.data.building.profileCalendar.profileDayTypes)

  useEffect(() => {
    if (hasProfileDayTypes) {
      const sortedProfileDayTypes = sortProfileDayTypes(props.data.building.profileCalendar.profileDayTypes)
      setSortedProfileDayTypes(sortedProfileDayTypes)
      setYearData(getYearData(activeYear, sortedProfileDayTypes))
      if (selectedProfileDayType == null && sortedProfileDayTypes.length > 0) {
        setSelectedProfileDayType(sortedProfileDayTypes[0])
      }
      if (selectedProfileDayType && sortedProfileDayTypes.length && -1 === Array.from(JSON.parse(JSON.stringify(sortedProfileDayTypes))).findIndex(d => d.id === selectedProfileDayType.id)) {
        setSelectedProfileDayType(sortedProfileDayTypes[0])
      }
    }
  }, [props.data, activeYear, hasProfileDayTypes, selectedProfileDayType])

  const handleChangeYear = (event) => {
    setActiveYear(event.target.value)
  }

  const handleProfileDayTypeSelectedChange = (event, value) => {
    setSelectedProfileDayType(Array.from(sortedProfileDayTypes).find(d => d.id === value))
  }

  const handleCalendarClick = async calendarEntry => {
    let profileDayTypes = JSON.parse(JSON.stringify(sortedProfileDayTypes));
    const profileDayTypeIndex = Array.from(profileDayTypes).findIndex(d=>d.id===selectedProfileDayType.id);
    if (calendarEntry){
      const yearDayIndex = Array.from(profileDayTypes[profileDayTypeIndex].yearDays).indexOf(calendarEntry.dateString);
      if (yearDayIndex > -1) {
        profileDayTypes[profileDayTypeIndex].yearDays.splice(yearDayIndex, 1);
      } else {
        profileDayTypes[profileDayTypeIndex].yearDays.push(calendarEntry.dateString)
      }
    }
    setSortedProfileDayTypes(profileDayTypes)
    setYearData(getYearData(activeYear, profileDayTypes));

    await props.updateProfileDayTypeYearDaysMutation({
      variables: {
        id: selectedProfileDayType.id,
        yearDays: profileDayTypes[profileDayTypeIndex].yearDays,
      },
      refetchQueries: [{
          query: updateCache,
          variables: {
            projectID: props.match.params.projectID,
          }
      }],
    })
  };

  const { classes } = props

  return (
    <div>
      <Paper style={{ padding: 25 }}>
        {props.data.error
          ? <Typography gutterBottom>Error fetching chart data!</Typography>
          : props.data.loading
          ? <CircularProgress size={50} color="secondary" />
          : (!selectedProfileDayType || !hasProfileCalendar || !hasProfileDayTypes)
          ? <Typography gutterBottom>No data</Typography>
          : <Grid container alignItems="center" spacing={4}>
            <Grid item xs={10} sm={10} md={10} lg={10} style={{textAlign: "center"}}>
              <DropDownYearCalendar
                building={props.data.building}
                value={activeYear}
                onChange={handleChangeYear}
                className={classes.textField}
                margin="normal"
                >
              </DropDownYearCalendar>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} style={{textAlign: "center"}}>
              <XYPlot width={1300} height={180} margin={{top:20, left: 120, bottom: 5, right: 5}}>
                <XAxis hideLine orientation="top" tickSize={0} tickValues={[2,7,12,18,23,28,34,39,44.5,50,55.25,60.75]} tickFormat={v => monthNamesDict[parseInt((v+7)/5.3, 10)]} />
                <YAxis hideLine tickSize={0} tickTotal={7} tickFormat={v => daysOfTheWeek[parseInt(7-v, 10)]} />
                {hoveredCell ? (
                  <Hint
                    value={{"x":hoveredCell.x, "y":hoveredCell.y, "dateHintTitle":hoveredCell.dateHintTitle, "dateHintValue":hoveredCell.dateHintValue}}
                    format={v => [{"title":v.dateHintTitle, "value":v.dateHintValue}]}
                  />
                ) : null}
                <LabelSeries
                  data={yearData}
                  labelAnchorX="middle"
                  labelAnchorY="middle"
                />
                <VerticalRectSeries
                  data={yearData}
                  colorType="literal"
                  opacity={0.7}
                  onValueMouseOver={v => {
                    document.body.style.cursor = "pointer";
                    setHoveredCell( v.x && v.y ? v : false )
                  }}
                  onValueMouseOut={v => {
                    document.body.style.cursor = "default";
                    setHoveredCell(false)
                  }}
                  onValueClick={handleCalendarClick}
                />
              </XYPlot>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} style={{textAlign: "center"}}>
              <FormControl component="fieldset" margin="dense">
                <RadioGroup
                  row
                  aria-label="profileDayTypeRadio"
                  name="profileDayTypeRadio"
                  className={classes.group}
                  value={selectedProfileDayType.id}
                  onChange={handleProfileDayTypeSelectedChange}
                >{Array.from(sortedProfileDayTypes)
                    .filter(profileDayType => profileDayType.priority > 0)
                    .map(profileDayType => (
                      <FormControlLabel
                        key={profileDayType.id}
                        value={profileDayType.id}
                        control={<Radio style={{color: profileDayType.color}} />}
                        label={profileDayType.displayName}
                      />
                ))}
                </RadioGroup>
              </FormControl>
              { selectedProfileDayType
                ? <Typography style={{textAlign: "center"}}>
                    <br/>
                    Clicking on a day in the calendar will add/remove the <b>"{selectedProfileDayType.displayName}"</b> designation on that day.
                    <br/>
                    Depending on the priorities, this may or may not be immediately visible in the visualization.
                  </Typography>
                : <Typography><br/><br/></Typography>
              }
            </Grid>
          </Grid>
        }
      </Paper>
    </div>
  )
}

ConfigComponentCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ConfigComponentCalendarQuery = gql`
query ConfigComponentCalendarQuery ($projectID: String!) {
  profileDaySchedules (projectID: $projectID) {
    id
    displayName
    payloadType
    payload
  }
  profileContexts (projectID: $projectID) {
    id
    displayName
    scalingType
    scalingParameters
    profileContextSchedules {
      id
      scalingParameters
      profileDayType {
        id
        displayName
      }
      profileDaySchedule {
        id
        displayName
      }
    }
    systemMetricConversions {
      id
      targetSystemMetric {
        id
        system {
          id
          displayName
        }
        systemMetricTemplate {
          id
          displayName
        }
      }
    }
  }
  building (where: { projectID: $projectID }) {
    id
    displayName
    profileCalendar {
      id
      profileDayTypes {
        id
        displayName
        priority
        weekDays
        yearDays
        color
        profileContextSchedules {
          id
          scalingParameters
          profileDayType {
            id
            displayName
          }
          profileDaySchedule {
            id
            displayName
          }
        }
        useSettingsFromProfileDayType {
          id
          displayName
        }
      }
    }
  }
}`;

const updateProfileDayTypeYearDays = gql`
mutation updateProfileDayTypeYearDays($id: ID!, $yearDays: [String!]) {
  updateProfileDayTypeYearDays(
    id: $id
    yearDays: $yearDays
  ) { id }
}`;

const updateCache = gql`
query updateCache($projectID: String!) {
  profileDayTypes(projectID: $projectID) {
    id
    displayName
    priority
    weekDays
    yearDays
    color
    profileContextSchedules {
      id
      scalingParameters
      profileDayType {
        id
        displayName
      }
      profileDaySchedule {
        id
        displayName
      }
    }
    useSettingsFromProfileDayType {
      id
      displayName
    }
  }
}`;

export default compose(
  graphql(ConfigComponentCalendarQuery, { options: (props) => ({ variables: { projectID: props.match.params.projectID }}) }),
  graphql(updateProfileDayTypeYearDays, {name : 'updateProfileDayTypeYearDaysMutation'}),
  graphql(updateCache, {name : 'updateCache', options: props => ({variables: {projectID: props.match.params.projectID} })}),
)(withStyles(styles)(ConfigComponentCalendar));
