import React from 'react'

import { NavLink } from 'react-router-dom'
import Tab from '@material-ui/core/Tab'


export const LinkTab = props => {
  const {url} = props
  const t = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />)
  return (
      <Tab component={t} value={url} to={url} {...props}></Tab>
  )
}