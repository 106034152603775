import React, {Component} from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import moment from 'moment'

class ReportList extends Component {
    static propTypes = {
        data: PropTypes.object,
    }

    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Report name</TableCell>
                    <TableCell>Creation date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {this.props.data.error ? (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography gutterBottom>Error fetching building configuration!</Typography>
                        </TableCell>
                    </TableRow>
                ) : ( this.props.data.loading || ! this.props.data.me ) ? (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <CircularProgress size={50} color="secondary" />
                        </TableCell>
                    </TableRow>
                ) : this.props.data.me.client.buildings.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={2}>
                        <Typography gutterBottom>No data available</Typography>
                        </TableCell>
                    </TableRow>
                ) : (
                    this.props.data.me.client.buildings[0].reports.map((report) =>
                        <TableRow key={report.id}>
                            <TableCell>
                                <a href={report.file.url} target="_blank" rel="noopener noreferrer" download={report.fileName} style={{textDecoration:"none"}}>
                                    <Button>
                                        {report.fileName}&nbsp;&nbsp;
                                        <CloudDownloadIcon />
                                    </Button>
                                </a>
                            </TableCell>
                            <TableCell title={moment(report.fileCreatedAt).local().format("dddd, MMMM Do YYYY, HH:mm")}>{moment(report.fileCreatedAt).fromNow()}</TableCell>
                        </TableRow>
                    )
                )}
                </TableBody>
            </Table>
        )
    }
}

const getCurrentUser = gql`
query getCurrentUser ($projectID: String!) {
    me {
        id
        client {
            id
            buildings (where: {projectID: $projectID}) {
                id
                projectID
                reports {
                    id
                    fileName
                    fileCreatedAt
                    file {
                        id
                        url
                    }
                }
            }
        }
    }
}`

export default graphql(getCurrentUser, {
    options: (props) => ({ variables: { projectID: props.match.params.projectID } })
})(ReportList);
