import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import ConfigZoning from './ConfigZoning'

class ConfigComponentZone extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  }

  render() {
    return (
      <div>
        <div>&nbsp;</div>
        {this.props.data.error ? (
          <Grid item xs={12}>
              <Typography gutterBottom>Error fetching building configuration!</Typography>
          </Grid>
        ) : (!this.props.data.zonings || this.props.data.loading) ? (
            <Grid item xs={12}>
                <CircularProgress size={50} color="secondary" />
            </Grid>
        ) : (
          this.props.data.zonings.map((zoning, index) => {
            return (
              <ConfigZoning
                key={zoning.id}
                zoning={zoning}
                spaces={this.props.data.spaces}
              />
            )
          })
        )}
      </div>
    );
  }
}

const TabZoneQuery = gql`
query TabZoneQuery ($projectID: String!) {
  spaces (projectID: $projectID) {
    id
    displayName
    systemTemplate {
      id
      name
    }
    parentSpace {
      id
      displayName
    }
    childSpaces {
      id
      displayName
    }
  }
  zonings (projectID: $projectID) {
    id
    displayName
    zones {
      id
      displayName
      spaces {
        id
        displayName
      }
    }
  }
}`

export default graphql(TabZoneQuery, {
  options: (props) => ({ variables: { projectID: props.match.params.projectID } })
})(ConfigComponentZone);
