import React from 'react'

import ProjectOverviewBuildingStates from '../components/ProjectOverviewBuildingStates'
import ProjectOverviewFeatures from '../components/ProjectOverviewFeatures'
import ProjectOverviewDeployments from '../components/ProjectOverviewDeployments'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

class ProjectOverview extends React.Component {
  render() {
    return (
      <div>
        <div>
          <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Building States</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectOverviewBuildingStates />
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Feature Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectOverviewFeatures />
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Deployment Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectOverviewDeployments />
            </AccordionDetails>
          </Accordion>
        </div>
        <div><br/></div>
      </div>
    )
  }
}

export default ProjectOverview;
