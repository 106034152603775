import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';

import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
  }),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

const source_options = [
  {
    value: 'flanders',
    label: 'Flemish Region',
    reference: '',
    value_ele: 0.330,
    value_gas: 0.202,
  },
  {
    value: 'brussels',
    label: 'Brussels Region',
    reference: '',
    value_ele: 0.395,
    value_gas: 0.220,
  },
  {
    value: 'netherlands',
    label: 'The Netherlands',
    value_ele: 0.475,
    value_gas: 0.193,
  },
  {
    value: 'custom',
    label: 'Custom Value',
  },
];

const INPUT_RULES = {
  value_gas: [0, 1],
  value_ele: [0, 1],
}

class DataInputCost extends React.Component {
  state = {
    id_ele: null,
    id_gas: null,
    id_heat: null,
    id_cold: null,
    source: "custom",
    reference: "",
    value_ele: "",
    value_gas: "",
    value_heat: "",
    value_cold: "",
    form: {
      isValid: true,
      inputs: {
        value_ele: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_gas: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_heat: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        },
        value_cold: {
          isValid: true,
          hint: "Value must be >= 0 and <= 1",
        }
      }
    }  
  };

  setStateFromUserInput = userInput => {
    this.setState(userInput)
  }

  handleUserInput = props => {
    if (props.data.building && props.data.building.systemMetrics) {
      const sm_gas = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_gas_tariff");
      if (sm_gas) {
        this.setStateFromUserInput(sm_gas.userInput);
        this.setState({id_gas: sm_gas.id});
      }
      const sm_ele = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_electricity_tariff");
      if (sm_ele) {
        this.setStateFromUserInput(sm_ele.userInput);
        this.setState({id_ele: sm_ele.id});
      }
      const sm_heat = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_heat_tariff");
      if (sm_heat) {
        this.setStateFromUserInput(sm_heat.userInput);
        this.setState({id_heat: sm_heat.id});
      }
      const sm_cold = Array.from(props.data.building.systemMetrics).find(sm => sm.systemMetricTemplate.name==="general_cold_tariff");
      if (sm_cold) {
        this.setStateFromUserInput(sm_cold.userInput);
        this.setState({id_cold: sm_cold.id});
      }
    }
  }

  componentDidMount() {
    this.handleUserInput(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleUserInput(nextProps)
  }

  handleUpdate = () => {
    if (this.state.id_gas && this.state.id_ele) {
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_ele,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele,
            value_heat: this.state.value_heat, value_cold: this.state.value_cold},
            payload: {value: this.state.value_ele},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_heat,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele,
              value_heat: this.state.value_heat, value_cold: this.state.value_cold},
            payload: {value: this.state.value_heat},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
            id: this.state.id_cold,
            userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele,
              value_heat: this.state.value_heat, value_cold: this.state.value_cold},
            payload: {value: this.state.value_cold},
        }
      })
      this.props.updateSystemMetricPayloadMutation({
        variables: {
          id: this.state.id_gas,
          userInput: {source: this.state.source, reference: this.state.reference, value_gas: this.state.value_gas, value_ele: this.state.value_ele,
            value_heat: this.state.value_heat, value_cold: this.state.value_cold},
          payload: {value: this.state.value_gas},
      },
        refetchQueries: [{
            query: updateCache,
            variables: {
              projectID: this.props.match.params.projectID,
            }
        }],
      }).then(response => {
        //after response
      })
    }
  }
  
  handleChangeSource = (event) => {
    this.setState({ source: event.target.value });
    this.setState({ reference: source_options.find(s => s.value===event.target.value).reference });
    this.setState({ value_ele: source_options.find(s => s.value===event.target.value).value_ele });
    this.setState({ value_gas: source_options.find(s => s.value===event.target.value).value_gas });
    this.setState({ value_heat: source_options.find(s => s.value===event.target.value).value_heat });
    this.setState({ value_cold: source_options.find(s => s.value===event.target.value).value_cold });
  }
  handleChange = name => event => {
    let form = this.state.form
    if (name in INPUT_RULES) {
      const [min, max] = INPUT_RULES[name];
      const value = event.target.value;
      form.inputs[name].isValid = (value >= min && value <= max);
    }
    form.isValid = Object.keys(form.inputs).every((inputName, i) => {
      return form.inputs[inputName].isValid;
    })
    this.setState({form})
    this.setState({[name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item lg={12}>
            <Typography variant="subtitle1">
              Please fill out average or fixed energy prices for your building.
            </Typography>
          </Grid>
          {/* <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Source
            </Typography>
          </Grid>
          <Grid item lg={10}>
          <form className={classes.container} autoComplete="off">
              <TextField
                id="source"
                select
                className={classes.textField}
                value={this.state.source}
                onChange={this.handleChangeSource}
                margin="normal"
              >
                {source_options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </Grid> */}
          {/* <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Reference
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="reference"
                className={classes.textField}
                value={this.state.reference}
                onChange={this.handleChange('reference')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid> */}
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Gas
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_gas"
                error={!this.state.form.inputs.value_gas.isValid}
                className={classes.textField}
                helperText={"Cost in Euro/kWh " + (this.state.form.inputs.value_gas.isValid ? "" : "(" + this.state.form.inputs.value_gas.hint + ")")}
                value={this.state.value_gas}
                onChange={this.handleChange('value_gas')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
            Electricity
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_ele"
                error={!this.state.form.inputs.value_ele.isValid}
                className={classes.textField}
                helperText={"Cost in Euro/kWh " + (this.state.form.inputs.value_ele.isValid ? "" : "(" + this.state.form.inputs.value_ele.hint + ")" )}
                value={this.state.value_ele}
                onChange={this.handleChange('value_ele')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Heat
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_heat"
                error={!this.state.form.inputs.value_heat.isValid}
                className={classes.textField}
                helperText={"Cost in Euro/kWh " + (this.state.form.inputs.value_heat.isValid ? "" : "(" + this.state.form.inputs.value_heat.hint + ")")}
                value={this.state.value_heat}
                onChange={this.handleChange('value_heat')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              Cold
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <form className={classes.container} autoComplete="off">
              <TextField
                id="value_cold"
                error={!this.state.form.inputs.value_cold.isValid}
                className={classes.textField}
                helperText={"Cost in Euro/kWh " + (this.state.form.inputs.value_cold.isValid ? "" : "(" + this.state.form.inputs.value_cold.hint + ")")}
                value={this.state.value_cold}
                onChange={this.handleChange('value_cold')}
                margin="normal"
                disabled={this.state.source!=="custom"}
              />
            </form>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6" align="right">
              &nbsp;
            </Typography>
          </Grid>
          <Grid item lg={10}>
            <Button className={classes.button} variant="contained" onClick={this.handleUpdate} disabled={!this.state.form.isValid}>
              <Save className={classes.leftIcon} />
              Save energy prices
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

DataInputCost.propTypes = {
  classes: PropTypes.object.isRequired,
};

const generalCostSystemMetrics = gql`
query generalCostSystemMetrics($projectID: String!) {
  building(where: {projectID: $projectID}) {
      systemMetrics(
        where:{ AND: [
          {
            system:{
              building:{projectID: $projectID},
              systemTemplate:{name: "systemTemplate_general"}
            },
            systemMetricTemplate:{ name_in: ["general_electricity_tariff", "general_gas_tariff", "general_heat_tariff", "general_cold_tariff"]}
          },{
            systemMetricTags_none: { key: "hide" value: "true" }
          }
        ]
        }) {
            id
            payload
            userInput
            systemMetricTags {
              id
              key
              value
            }
            systemMetricTemplate {
                id
                name
            }
        }
    }
}`

const updateSystemMetricPayload = gql`
mutation updateSystemMetricPayload($id: ID!, $userInput: Json!, $payload: Json!) {
  updateSystemMetricPayload(
    id: $id
    payload: $payload
    userInput: $userInput
  ) { id }
}`;

const updateCache = gql`
query updateCache($projectID: String!) {
    building(where: {projectID: $projectID}) {
      id
      systemMetrics(
        where:{ AND: [
          {
            system:{
              building:{projectID: $projectID},
              systemTemplate:{name: "systemTemplate_general"}
            },
            systemMetricTemplate:{ name_in: ["general_electricity_tariff", "general_gas_tariff", "general_heat_tariff", "general_cold_tariff"]}
          },{
            systemMetricTags_none: { key: "hide" value: "true" }
          }
        ]
        }) {
            id
            payload
            userInput
            systemMetricTags {
              id
              key
              value
            }
            systemMetricTemplate {
                id
                name
            }
        }
    }
}`;

export default compose(
  graphql(generalCostSystemMetrics, {options: (props) => ( {variables: {projectID: props.match.params.projectID}} )}),
  graphql(updateSystemMetricPayload, {name : 'updateSystemMetricPayloadMutation'}),
  graphql(updateCache, {name : 'updateCache', options: props => {
    return {variables: {projectID: props.match.params.projectID}}
  }}),
)(withStyles(styles)(DataInputCost));
