import React from 'react';
import withTheme from '@material-ui/core/styles/withTheme';

import moment from 'moment';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import 'react-vis/dist/style.css'
import {FlexibleWidthXYPlot, XAxis, VerticalGridLines, LineSeries, AreaSeries} from 'react-vis';

const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
const hours_in_milliseconds = hours.map(h => h * 3600000);

const profileDaySchedulePayloadTypes = [
  {
    name: "timeseries",
    displayName: "Data points",
    helpText: 'The resulting profile will consist of the linearly interpolated lines between the data points.<br/>All values have to be a decimal value between 0 and 1<br/>Example: {"00:00": "0.0", ..., "07:00": "0.2", ..., "12:00":"1.0", "12:30": "0.4", "13:30": "0.4", "14:00": "1.0", ... , "23:00":"0.0"}',
  },
  {
    name: "timeranges",
    displayName: "Time blocks",
    helpText: 'The resulting profile is the combination of the time blocks. Periods not covered by the time blocks will be considered to be 0.<br/>All values have to be a decimal value between 0 and 1<br/>Example: {"07:00-12:15": "1.0", "12:15-13:45": "0.4", "13:45-19:00": "1.0"}',
  },
];

const getData = (payload) => {
  var result = []
  if ("map" in payload) {
    if (payload.length > 0) {
      result=payload
        .sort()
        .reduce((acc,timeRange) => (
          acc.concat([
            {x: moment("1970-01-01T" + timeRange.split("-")[0] + "+00:00").toDate(), y: 0},
            {x: moment("1970-01-01T" + timeRange.split("-")[0] + "+00:00").add(1, 'seconds').toDate(), y: 1},
            {x: moment((timeRange.split("-")[1]==="00:00") ? "1970-01-02T00:00+00:00" : "1970-01-01T" + timeRange.split("-")[1] + "+00:00").add(-1, 'seconds').toDate(), y: 1},
            {x: moment((timeRange.split("-")[1]==="00:00") ? "1970-01-02T00:00+00:00" : "1970-01-01T" + timeRange.split("-")[1] + "+00:00").toDate(), y: 0},
          ])
        ), [])
    }
  } else {
    result = Object.keys(payload)
      .sort()
      .map(time => ({
        x: (time==="23:59" || time==="24:00") ? moment("1970-01-02T00:00+00:00").toDate() : moment("1970-01-01T" + time + "+00:00").toDate(),
        y: payload[time],
      }))
  }
  if (result.length===0 || result[0].x !== moment("1970-01-01T00:00+00:00").toDate()) {
    result.splice(0,0,{x: moment("1970-01-01T00:00+00:00").toDate(), y: 0})
  }
  if (result.length===0 || result[result.length - 1].x !== moment("1970-01-02T00:00+00:00").toDate()) {
    result.push({x: moment("1970-01-02T00:00+00:00").toDate(), y: 0})
  }
  return result
}

class ProfileDaySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.profileDaySchedule.id,
      displayName: this.props.profileDaySchedule.displayName,
      payloadType: this.props.profileDaySchedule.payloadType,
      payload: this.props.profileDaySchedule.payload,
      primaryColor: this.props.theme.palette.primary.main,
      secondaryColor: this.props.theme.palette.secondary.main,
    };
  }

  handleDisplayNameChange = (event, displayName) => {
    //this.setState({ displayName });
  };

  handlePayloadTypeSelectionChange = (event, payloadType) => {
    //this.setState({ payloadType });
  };

  handlePayloadChange = (event, payloadString) => {
    //this.setState({ payload: JSON.parse(payloadString) });
  };

  render() {
    return (
      <div>
        <FormControl>
          <Input
            id="displayName-input"
            value={this.state.displayName}
            onChange={this.handleDisplayNameChange}
          />
          <FormHelperText>Name</FormHelperText>
        </FormControl>
        &nbsp;&nbsp;&nbsp;
        <FormControl>
          <Select
            value={this.state.payloadType}
            onChange={this.handlePayloadTypeSelectionChange}
            input={<Input id="payloadType-input" />}
          >
            {profileDaySchedulePayloadTypes.map(profileDaySchedulePayloadType => (
              <MenuItem key={profileDaySchedulePayloadType.name} value={profileDaySchedulePayloadType.name}>
                {profileDaySchedulePayloadType.displayName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Format</FormHelperText>
        </FormControl>
        &nbsp;&nbsp;&nbsp;
        <FormControl>
          <Input
            id="payload-input"
            value={JSON.stringify(this.state.payload)}
            onChange={this.handlePayloadChange}
            fullWidth={true}
          />
          <FormHelperText>Profile</FormHelperText>
        </FormControl>
        <br/>
        <FlexibleWidthXYPlot height={100} yDomain={[0, 1]}>
          <XAxis tickSize={0} tickValues={hours_in_milliseconds} tickFormat={v => parseInt(v/3600000, 10)} />
          <VerticalGridLines tickValues={hours_in_milliseconds} />
          <AreaSeries data={getData(this.state.payload)} color={this.state.secondaryColor} />
          <LineSeries data={getData(this.state.payload)} color={this.state.primaryColor} strokeWidth="3px" />
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}

export default withTheme(ProfileDaySchedule);