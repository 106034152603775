import React from 'react'
import TableBody from '@material-ui/core/TableBody'

import LogBookEntry from './LogBookEntry'

const LogBookEntryList = props => {
    return (
        <TableBody>
            {props.entries.map((entry) => {
                return (<LogBookEntry
                    key={entry.id}
                    entry={entry}
                    projectID={props.projectID}
                />)
            })}
        </TableBody>
    )
}

export default LogBookEntryList;
