import React, {Component} from 'react'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import moment from 'moment'

class Building extends Component {
    render() {
        if (this.props.data.error) {
            return (<Typography gutterBottom>Error fetching form data!</Typography>)
        };
        if (this.props.data.loading) {
            return (<CircularProgress size={50} color="secondary" />)
        };
        if (!this.props.data.me) {
            return (<Typography gutterBottom>Rendering form ...</Typography>)
        };
        if (this.props.data.me.client.buildings.length === 0 && this.props.data.me.externalBuildingRoles.length === 0) {
            return (<Typography gutterBottom>No data available</Typography>)
        } else {
            const building = this.props.data.me.client.buildings.length > 0
                ? this.props.data.me.client.buildings[0]
                : this.props.data.me.externalBuildingRoles[0].building

            /* const colors = {
                common: {fill: '#18B331', stroke: '#18B331'},
                gas: {fill: building.chartColorElectricity ? building.chartColorElectricity : '#FFC000', stroke: building.chartColorElectricity ? building.chartColorElectricity : '#FFC000'},
                electricity: {fill: building.chartColorGas ? building.chartColorGas : '#2E75B6', stroke: building.chartColorGas ? building.chartColorGas : '#2E75B6'},
                common_savings: {fill: 'white', stroke: '#18B331'},  // grey
                gas_savings: {fill: 'white', stroke: building.chartColorElectricity ? building.chartColorElectricity : '#FFC000'},  // #FFD966
                electricity_savings: {fill: 'white', stroke: building.chartColorGas ? building.chartColorGas : '#2E75B6'},  // #9DC3E6
            } */

            const currentPeriodStart = moment(building.activationDate).set('year', moment(building.lastInvoiceMonth).year) < moment(building.lastInvoiceMonth) ? moment(building.activationDate).set('year', moment(building.lastInvoiceMonth).year()) : moment(building.activationDate).set('year', moment(building.lastInvoiceMonth).year() - 1)
            const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'years')
            
            const periodTextReference = "Reference period: " + moment(building.referencePeriodStartDate).format("MMM YYYY") + " - " + moment(building.referencePeriodStartDate).add(1, "years").format("MMM YYYY")
            const periodTextLast = "Previous period: " + previousPeriodStart.format("MMM YYYY") + " - " + currentPeriodStart.format("MMM YYYY")
            const periodTextCurrent = "Current period: " + currentPeriodStart.format("MMM YYYY") + " - " + moment(building.lastInvoiceMonth).format("MMM YYYY")

            const complaint_reduction_percentage = building.chartPayload ? building.chartPayload.complaint_reduction_percentage : 0
            const cost_savings_percentage = building.chartPayload ? building.chartPayload.cost_savings_percentage : 0
            const CO2_savings_tCO2eq = building.chartPayload ? building.chartPayload.CO2_savings_tCO2eq : 0
            const CO2_savings_cars = building.chartPayload ? building.chartPayload.CO2_savings_cars : 0

            //const chart_data = building.chartPayload ? building.chartPayload.chart_data : {consumption : [], cost: [], emission: []}
            
            return (
                <Paper style={{ padding: 25 }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Typography variant="h6">
                                {building.displayName}
                            </Typography>
                            <Typography>&nbsp;</Typography>
                            <img
                                src={building.image ? building.image.file.url: "/static/images/cards/generic_building_image.png"}
                                alt={building.displayName}
                                width="350"
                                style={{borderRadius: "10px"}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Typography variant="h6">
                                Building characteristics
                            </Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography>{[
                                (building.location.address ? (<span key="address">{building.location.address}</span>) : null),
                                (building.location.address && building.location.city ? (<span key="address-city"> - </span>) : null),
                                (building.location.city ? (<span key="city">{building.location.city}</span>) : null),
                                (building.location.city && building.location.country ? (<span key="city-country"> - </span>) : null),
                                (building.location.country ? (<span key="country">{building.location.country}</span>) : null),
                            ]}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography>{building.surfaceM2 ? "Managed Surface: " + building.surfaceM2 + " sqm" : ""}</Typography>
                            <Typography>{building.activationDateDashboard ? "Activation Date Dashboard: " + building.activationDateDashboard : ""}</Typography>
                            <Typography>{building.activationDateSteering ? "Activation Date Steering: " + building.activationDateSteering : ""}</Typography>
                            <Typography>{building.activationDateSavings ? "Activation Date Savings: " + building.activationDateSavings : ""}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography>{building.descriptionAssetManager ? building.descriptionAssetManager.split('\n').map(l => (<span key={l}>{l}<br/></span>)) : ""}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography>{building.descriptionSystems ? building.descriptionSystems.split('\n').map(l => (<span key={l}>{l}<br/></span>)) : ""}</Typography>
                            <Typography>{building.descriptionBMS ? "BMS: " + building.descriptionBMS : ""}</Typography>
                        </Grid>
                        {(building.displayName!=="__##NONE##__"
                        ? (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <Typography variant="h6">
                                </Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography>&nbsp;</Typography>
                            </Grid>
                        ) : ([
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key="results_text">
                                <Typography variant="h6">
                                    dnergy Results
                                </Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography><b>{parseInt(complaint_reduction_percentage, 10)}% less comfort complaints</b>.</Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography>Decrease in energy costs by <b>{parseInt(cost_savings_percentage, 10)}%</b>.</Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography>
                                    Decrease in CO2 emission of <b>{parseInt(CO2_savings_tCO2eq, 10)} tons/year</b>,<br/>
                                    or the equivalent of <b>{parseInt(CO2_savings_cars, 10)} typical passenger cars</b>.
                                </Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography>Decrease in operational costs as a result of reduced comfort complaints and less manual BMS finetuning.</Typography>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key="results_chart_consumption">
                                <Typography variant="h6">Specific energy consumption</Typography>
                                <Typography>[kWh / sqm]</Typography>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key="results_chart_cost">
                                <Typography variant="h6">Energy cost</Typography>
                                <Typography>[kEUR / year]</Typography>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key="results_chart_emission">
                                <Typography variant="h6">CO2 emission</Typography>
                                <Typography>[tCO2eq / year]</Typography>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"center"}} key="results_legend">
                            </Grid>,
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"center"}} key="results_print">
                                <Button variant="contained" style={{margin:"auto"}} onClick={window.print}>
                                    <Icon >print</Icon>&nbsp;&nbsp;&nbsp;Print
                                </Button>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"center"}} key="results_periods">
                                <Typography>{"* " + periodTextReference + " || " + periodTextLast + " || " + periodTextCurrent}</Typography>
                            </Grid>,
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:"center"}} key="results_savings_reference">
                                <Typography>
                                    ** Savings calculations are based on an automated reference model,
                                    which takes into account a number of building- and time-specific parameters.
                                </Typography>
                                <Typography>
                                    These parameters include, but are not limited to, degree-days and solar irradiation.
                                </Typography>
                            </Grid>
                        ]))}
                    </Grid>
                </Paper>
            );
        }
    }
}

const getCurrentUser = gql`
query getCurrentUser ( $projectID: String! ) {
    me {
        id
        externalBuildingRoles ( where: { building: { projectID: $projectID } } ) {
            building {
                id
                projectID
                contactName
                contactEmail
                description
                displayName
                descriptionAssetManager
                descriptionSystems
                descriptionBMS
                surfaceM2
                referencePeriodStartDate
                activationDate
                activationDateDashboard
                activationDateSteering
                activationDateSavings
                lastInvoiceMonth
                lastDataPushDateTime
                chartColorElectricity
                chartColorGas
                location {
                  id
                  address
                  city
                  country
                }
                image {
                  id
                  file {
                    id
                    url
                  }
                }
            }
        }
        client {
            id
            buildings ( where: { projectID: $projectID } ) {
                id
                projectID
                contactName
                contactEmail
                description
                displayName
                descriptionAssetManager
                descriptionSystems
                descriptionBMS
                surfaceM2
                referencePeriodStartDate
                activationDate
                activationDateDashboard
                activationDateSteering
                activationDateSavings
                lastInvoiceMonth
                lastDataPushDateTime
                chartColorElectricity
                chartColorGas
                location {
                  id
                  address
                  city
                  country
                }
                image {
                  id
                  file {
                    id
                    url
                  }
                }
            }
        }
    }
}`

export default graphql(getCurrentUser, {
    options: (props) => ({ variables: { projectID: props.match.params.projectID } })
  })(Building);
