import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

import { DateTimePicker } from '@material-ui/pickers';

import { CurrentUserContext } from '../contexts/CurrentUser';

//graphql(logTags, {
//    options: (props) => ({ variables: { projectID: props.projectID } })
//}),
//graphql(createLogEntry, {name : 'createLogEntryMutation'})

const LogBookEntryInput = (props) => {
    const [inputName, setInputName] = useState("")
    const [inputDescription, setInputDescription] = useState("")
    const [inputStart, setInputStart] = useState(new Date())
    const [inputEnd, setInputEnd] = useState(new Date())
    const [inputTagsIds, setInputTagsIds] = useState([])

    const { data, loading, error } = useQuery(logTags, { variables: { projectID: props.projectID }})
    const [ createLogEntryMutation ] = useMutation(createLogEntry)

    const _reinitialize_state = () => {
        setInputName("")
        setInputDescription("")
        setInputStart(new Date())
        setInputEnd(new Date())
        setInputTagsIds([])
    }

    const handleNameChange = event => { setInputName(event.target.value) }
    const handleDescriptionChange = event => { setInputDescription(event.target.value) }
    const handleStartChange = dateTime => { setInputStart(dateTime) }
    const handleEndChange = dateTime => { setInputEnd(dateTime) }
    const handleTagsIdsChange = event => { setInputTagsIds(event.target.value) }

    const _onSend = () => {
        createLogEntryMutation({
            variables: {
                name: inputName,
                description: inputDescription,
                start: inputStart,
                end: inputEnd,
                logTags: inputTagsIds.map(id => ({"id": id})),
                projectID: props.projectID,
            },
            refetchQueries: [{
                query: updateLogEntryCache,
                variables: {
                    projectID: props.projectID,
                }
            }],
        }).then(response => {
            _reinitialize_state();
        })
    }
  
    return (
        <form autoComplete="off">
            <CurrentUserContext.Consumer>{ me => { return ( me.role === "READER" ? null :
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>New Logbook entry</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <FormControl>
                                <Input
                                    id="name-input"
                                    value={inputName}
                                    onChange={handleNameChange}
                                />
                                <FormHelperText>Name</FormHelperText>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl>
                                <Input
                                    id="description-input"
                                    value={inputDescription}
                                    onChange={handleDescriptionChange}
                                />
                                <FormHelperText>Description</FormHelperText>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl>
                                <Select
                                    multiple
                                    value={inputTagsIds}
                                    onChange={handleTagsIdsChange}
                                    input={<Input id="tagsids-input" />}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 200 }}}}
                                >
                                    {error ? (
                                        null
                                    ) : loading ? (
                                        null
                                    ) : !data.building ? (
                                        null
                                    ) : data.building.logTags ? (
                                        data.building.logTags.map(tag => (
                                            <MenuItem
                                                key={tag.id}
                                                value={tag.id}
                                                style={{
                                                fontWeight: inputTagsIds.indexOf(tag.id) !== -1 ? '500' : '400',
                                                }}
                                            >
                                                {tag.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        null
                                    )}
                                </Select>
                                <FormHelperText>Tags</FormHelperText>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl>
                                <DateTimePicker
                                    format="YYYY-MM-DD HH:mm"
                                    value={inputStart}
                                    onChange={handleStartChange}
                                    />
                                <FormHelperText>Start</FormHelperText>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl>
                                <DateTimePicker
                                    format="YYYY-MM-DD HH:mm"
                                    value={inputEnd}
                                    onChange={handleEndChange}
                                />
                                <FormHelperText>End</FormHelperText>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <Fab size="small" color="primary" aria-label="add" onClick={_onSend}>
                                <Icon>add</Icon>
                            </Fab>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            )}}
            </CurrentUserContext.Consumer>
        </form>
    )
}

const logTags = gql`
query logTags($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id
        logTags {
            id
            name
        }
    }
}`

const createLogEntry = gql`
mutation createLogEntryMutation($projectID: String!, $name: String!, $description: String!, $start: DateTime!, $end: DateTime, $logTags: [LogTagWhereUniqueInput!]) {
    createLogEntry(
        projectID: $projectID
        name: $name
        description: $description
        start: $start
        end: $end
        logTags: $logTags
    ) {
        id
        name
        description
        start
        end
        building {
            id
        }
        logTags {
            id
            name
        }
    }
}`

const updateLogEntryCache = gql`
query updateCache($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id
        displayName
        logEntries {
            id
            name
            description
            start
            end
            logTags {
                id
                name
            }
        }
    }
}`;

export default LogBookEntryInput;
